import { InputAdornment } from "@mui/material";
import { getTwitterProfile } from "api/profile.api";
import Adornment from "components/UI/Adornment/Adornment";
import { useEffect, useMemo, useState } from "react";
import CustomTextField from "../CustomTextField";
import DetailsFound from "../DetailsFound/DetailsFound";
import AddToExistingProfile from "./AddToExistingProfile";
import AddToExistingProfileForm from "./AddToExistingProfileForm";
import classes from "./FollowForm.module.css";
import { CircularProgress } from "@mui/material";
import { stripTags } from "utils/misc";
import AddSocialPlatformsButton from "./AddSocialPlatformsButton";
import { isEmpty, keys, pickBy } from "lodash";

const FollowForm = ({
  onAddToBundle,
  onAddressFollow,
  name = "",
  socials,
  bio = "",
  isEditMode = false,
  onProfileEdit,
  ownProfile,
}) => {
  const [touchedFields, setTouchedFields] = useState({});
  const [isTwitterFetching, setIsTwitterFetching] = useState(false);
  const [twitterHandleState, setTwitterHandleState] = useState(
    socials?.twitter ?? ""
  );

  const visibleSocials = useMemo(
    () =>
      keys(pickBy(socials, (value) => !isEmpty(value)))?.filter(
        (item) => item !== "twitter"
      ),
    [socials]
  );
  const [visiblePlatforms, setVisiblePlatforms] = useState(
    visibleSocials ?? []
  );
  const [telegramState, setTelegramState] = useState(socials?.telegram ?? "");
  const [linkedInState, setLinkedInState] = useState(socials?.linkedin ?? "");
  const [githubState, setGithubState] = useState(socials?.github ?? "");
  const [lensState, setLensState] = useState(socials?.lens ?? "");
  const [farcasterState, setFarcasterState] = useState(
    socials?.farcaster ?? ""
  );
  const [twitterDetails, setTwitterDetails] = useState(null);
  const [nameState, setNameState] = useState(
    name && !name.startsWith("0x") ? name : ""
  );
  const [bioState, setBioState] = useState(stripTags(bio) || "");
  const [showTwitterDetails, setShowTwitterDetails] = useState(true);
  const [useDetails, setUseDetails] = useState(false);
  const [addToExistingProfile, setAddToExistingProfile] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const disabled = !nameState || !bioState || nameState.startsWith("0x");
  const twitterNotFound =
    !isTwitterFetching &&
    !twitterDetails &&
    twitterHandleState.trim().length > 0;
  useEffect(() => {
    setTwitterDetails(null);
    setShowTwitterDetails(true);
    let timeOutId = null;
    const controller = new AbortController();

    if (
      twitterHandleState.trim().length > 0 &&
      twitterHandleState.trim().length < 16
    ) {
      setIsTwitterFetching(true);
      timeOutId = setTimeout(() => {
        getTwitterProfile(twitterHandleState, controller.signal)
          .then((response) => {
            setTwitterDetails(response.data.data);
            setIsTwitterFetching(false);
          })
          .catch((err) => {
            setIsTwitterFetching(false);
          });
      }, 500);
    } else {
      setIsTwitterFetching(false);
    }

    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
      setIsTwitterFetching(false);
    };
  }, [twitterHandleState, setTwitterDetails, setTwitterHandleState]);

  const onUseDetails = () => {
    setShowTwitterDetails(false);
    setUseDetails(true);
    setNameState(twitterDetails.name);
    setBioState(twitterDetails.description);
  };

  const onDiscard = () => {
    setShowTwitterDetails(false);
  };

  const onFollow = () => {
    if (disabled) {
      setTouchedFields({ name: true, bio: true });
      return;
    }
    setIsUpdating(true);
    if (isEditMode) {
      onProfileEdit({
        nickname: nameState,
        bio: bioState,
        twitter: twitterHandleState,
        telegram: telegramState,
        linkedIn: linkedInState,
        github: githubState,
        lens: lensState,
        farcaster: farcasterState,
        useDetails,
      });
    } else {
      onAddressFollow({
        nickname: nameState,
        bio: bioState,
        twitter: twitterHandleState,
        telegram: telegramState,
        linkedIn: linkedInState,
        github: githubState,
        lens: lensState,
        farcaster: farcasterState,
        useDetails,
      });
    }
  };

  if (addToExistingProfile) {
    return (
      <AddToExistingProfileForm
        onBack={() => {
          setAddToExistingProfile(false);
        }}
        onAddToBundle={onAddToBundle}
      />
    );
  }
  const shouldShowTwitter = () => {
    if (ownProfile && twitterHandleState.trim().length === 0) {
      return false;
    }
    return true;
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.follow_form}>
      <h3 className={classes.title}>
        {ownProfile ? "Edit Your Profile" : "Contribute User Details"}
      </h3>

      <div className={classes.form_group}>
        {!isEditMode && (
          <AddToExistingProfile
            onClick={() => {
              setAddToExistingProfile(true);
            }}
          />
        )}
        {shouldShowTwitter() && (
          <div>
            <CustomTextField
              size="small"
              label="Twitter Handle"
              fullWidth
              disabled={ownProfile}
              value={twitterHandleState}
              variant="outlined"
              onChange={(e) => {
                setTwitterHandleState(e.target.value);
              }}
              {...(isTwitterFetching || !!twitterDetails
                ? {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {isTwitterFetching ? (
                            <CircularProgress color="inherit" size={12} />
                          ) : (
                            <Adornment
                              options={{
                                available: twitterDetails,
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <div className={classes.input_image_adornment}>
                          {(showTwitterDetails || useDetails) &&
                            twitterDetails?.profile_image_url && (
                              <img
                                src={twitterDetails?.profile_image_url}
                                alt="profile"
                                className={classes.img}
                              />
                            )}
                        </div>
                      ),
                    },
                  }
                : {})}
            />
            <p className={classes.twitter_information}>
              {isTwitterFetching && "Fetching from Twitter"}
              {twitterNotFound && "No user found with this handle"}
              {!isTwitterFetching &&
                twitterHandleState.trim().length === 0 &&
                "We will be able to fetch user details from their twitter profile"}
            </p>
          </div>
        )}

        {showTwitterDetails && twitterDetails && shouldShowTwitter() && (
          <DetailsFound
            details={twitterDetails}
            onDiscard={onDiscard}
            onUseDetails={onUseDetails}
          />
        )}
        {visiblePlatforms?.includes("telegram") && (
          <CustomTextField
            size="small"
            label="Telegram ID"
            fullWidth
            value={telegramState}
            onChange={(e) => {
              setTelegramState(e.target.value);
            }}
          />
        )}

        {visiblePlatforms?.includes("linkedin") && (
          <CustomTextField
            size="small"
            label="LinkedIn ID"
            fullWidth
            value={linkedInState}
            onChange={(e) => {
              setLinkedInState(e.target.value);
            }}
          />
        )}
        {visiblePlatforms?.includes("github") && (
          <CustomTextField
            size="small"
            label="Github ID"
            fullWidth
            value={githubState}
            onChange={(e) => {
              setGithubState(e.target.value);
            }}
          />
        )}
        {visiblePlatforms?.includes("lens") && (
          <CustomTextField
            size="small"
            label="Lens ID"
            fullWidth
            value={lensState}
            onChange={(e) => {
              setLensState(e.target.value);
            }}
          />
        )}
        {visiblePlatforms?.includes("farcaster") && (
          <CustomTextField
            size="small"
            label="Farcaster ID"
            fullWidth
            value={farcasterState}
            onChange={(e) => {
              setFarcasterState(e.target.value);
            }}
          />
        )}
        <div className={classes.error_group}>
          <CustomTextField
            size="small"
            label="Name or Username"
            fullWidth
            value={nameState}
            errorState={touchedFields?.["name"] && nameState?.length === 0}
            required
            variant="outlined"
            onBlur={() => setTouchedFields((prev) => ({ ...prev, name: true }))}
            onChange={(e) => {
              setNameState(e.target.value);
            }}
          />
          <p className={classes.error}>
            {touchedFields?.["name"] &&
              nameState?.length === 0 &&
              "Name cannot be blank"}
            {nameState.startsWith("0x") && "Name cannot start with 0x"}
          </p>
        </div>

        <div>
          <CustomTextField
            size="small"
            label="Bio"
            multiline
            rows={3}
            fullWidth
            value={bioState}
            errorState={
              (useDetails || touchedFields?.["bio"]) && bioState?.length === 0
            }
            required
            variant="outlined"
            onBlur={() => setTouchedFields((prev) => ({ ...prev, bio: true }))}
            onChange={(e) => {
              setBioState(e.target.value);
            }}
          />

          {(useDetails || touchedFields?.["bio"]) && bioState?.length === 0 && (
            <p className={classes.error}>Bio cannot be blank</p>
          )}
        </div>

        <AddSocialPlatformsButton
          visiblePlatforms={visiblePlatforms}
          setVisiblePlatforms={setVisiblePlatforms}
        />
      </div>

      <button
        className={`${classes.done} ${isUpdating ? classes.disabled : ""}`}
        onClick={onFollow}>
        {isUpdating ? "..." : isEditMode ? "Update" : "DONE"}
      </button>
    </div>
  );
};

export default FollowForm;
