import React, { forwardRef } from "react";
import { WrapperText } from "./ZxText.utils";
import styles from "./ZkText.module.scss";
import { TSTYLES } from "zxStyles/constants";
const ZxText = forwardRef(
  (
    {
      children,
      wrapper = "p",
      customClass = "",
      style = TSTYLES.body1,
      cursor = "inherit",
      color = "inherit",
      align = "left",
      width = "fit-content",
      textTransform = "none",
      whiteSpace = "normal",
      fontSize = null,
    },
    ref
  ) => {
    return (
      <WrapperText
        wrapper={wrapper}
        style={{
          cursor,
          textAlign: align,
          width,
          textTransform,
          whiteSpace,
          fontSize,
        }}
        className={`${customClass} ${styles[style]} ${styles[color]} ${styles.container}`}>
        {children}
      </WrapperText>
    );
  }
);

export default ZxText;
