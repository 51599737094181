import { signedRequest } from "api/api";

export const getDeepLink = async ({ url }) => {
  return await signedRequest({
    method: "post",
    path: "/api/fcm/get_dynamic_link",
    bodyText: JSON.stringify({
      url: url,
    }),
  });
};

export const discardAppDownloadNotification = async () => {
  return await signedRequest({
    method: "post",
    path: "/api/0xppl/discard_mobile_download_push_notification",
  });
};
