import classes from "./ProtocolHoverCard.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  FlexGrow,
  TypeTag,
  Divider,
  LoadingHoverCard,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { ReactComponent as ChevronDownIcon } from "assets/svg/chevron-down.svg";
import { removePlusOrMinusFromPriceChange } from "utils/misc";
import { getProtocolDetails } from "api/contract.api";
import { shortStartingAddress } from "utils/misc";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import ContractHoverCard from "components/V2/Contract/ContractHoverCard/ContractHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import { useQuery } from "@tanstack/react-query";

const ProtocolHoverCard = ({ contractData, protocolData, children }) => {
  if (!protocolData?.identifier) {
    return (
      <ContractHoverCard contractProfile={contractData}>
        {children}
      </ContractHoverCard>
    );
  }

  return (
    <PPLxHoverCard
      content={
        <ProtocolHoverCardContent
          data={protocolData}
          contractData={contractData}
        />
      }>
      {children}
    </PPLxHoverCard>
  );
};

const ProtocolHoverCardContent = ({ data, contractData }) => {
  const { data: protocolMetadata, isError } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ["protocol", data?.identifier, contractData?.address],
    retry: 1,
    queryFn: () =>
      getProtocolDetails({
        protocol: data?.identifier,
      }),
  });
  const protocolData = protocolMetadata ?? data;
  const tokenData =
    protocolData?.profiles != null
      ? protocolData?.profiles[
          `${protocolData?.token?.chain_id}_${protocolData?.token?.address}`
        ]
      : null;
  const getContractStringToShow = () => {
    if (protocolData?.name === contractData?.display_name) {
      return `Contract: ${shortStartingAddress(contractData?.address)}`;
    }
    return `Contract: ${contractData?.display_name} (${shortStartingAddress(
      contractData?.address
    )})`;
  };
  if (!protocolMetadata && isError !== true) {
    return <LoadingHoverCard />;
  }
  if (isError) return null;
  const link =
    protocolData?.link ??
    contractData?.protocol_details?.link ??
    contractData?.link;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.container}>
      <CustomColumn>
        <CustomRow justifyContent="space-between">
          <ConditionalLink to={link}>
            <Image
              src={
                protocolData?.image ??
                protocolData?.display_picture ??
                contractData?.display_picture
              }
              alt={
                protocolData?.name ??
                protocolData?.display_name ??
                contractData?.display_name
              }
              imageType={IMAGE_TYPES.CONTRACT}
              className={classes.image}
            />
          </ConditionalLink>
          <ExternalLinks links={protocolData?.external_links} />
        </CustomRow>
        <PaddingComponent height="12px" />
        <ConditionalLink to={link}>
          <div
            className={classes.title}
            title={
              protocolData?.name ??
              protocolData?.display_name ??
              contractData?.display_name
            }>
            {protocolData?.name.slice(0, 15)}
            {protocolData?.name?.length > 15 ? "..." : ""}
          </div>
        </ConditionalLink>
        <CustomRow justifyContent="space-between">
          <CustomRow flexWrap="wrap">
            {protocolData?.tvl?.display_value && (
              <span className={classes.subtitle}>
                TVL: {protocolData?.tvl?.display_value}
              </span>
            )}
            {!!protocolData?.tvl?.display_value &&
              !!protocolData?.chain_count && (
                <>
                  <PaddingComponent width="3px" />
                  <span className={classes.subtitle}>·</span>
                  <PaddingComponent width="3px" />
                </>
              )}
            {!!protocolData?.chain_count && (
              <span className={classes.subtitle}>
                {protocolData?.chain_count > 1
                  ? protocolData?.chain_count
                  : protocolData?.first_chain_name}
                {protocolData?.chain_count > 1 && " Chains"}
              </span>
            )}
          </CustomRow>
          <TypeTag text="Protocol" />
        </CustomRow>
        <PaddingComponent height="12px" />
        <Divider />
        <PaddingComponent height="8px" />
        {contractData?.display_name && (
          <CustomColumn gap="8px">
            <ConditionalLink
              to={contractData?.link}
              className={classes.subtitle}>
              {getContractStringToShow()}
            </ConditionalLink>
            {(tokenData ||
              protocolData?.token ||
              protocolData?.description) && <Divider />}
          </CustomColumn>
        )}
        {(tokenData || protocolData?.token) && (
          <CustomColumn>
            <PaddingComponent height="8px" />
            <CustomRow>
              <FlexGrow>
                {tokenData && (
                  <CustomRow>
                    <span className={classes.subtitle}>Token: </span>
                    <PaddingComponent width="8px" />
                    <ConditionalLink to={tokenData?.link}>
                      <Image
                        src={tokenData?.display_picture}
                        alt={tokenData?.display_name}
                        imageType={IMAGE_TYPES.CONTRACT}
                        className={classes.tokenImage}
                      />
                    </ConditionalLink>
                    <PaddingComponent width="6px" />
                    <ConditionalLink to={tokenData?.link}>
                      <span className={classes.subtitle}>
                        {tokenData?.token_details?.symbol ??
                          tokenData?.display_name}
                      </span>
                    </ConditionalLink>
                  </CustomRow>
                )}
              </FlexGrow>
              {protocolData?.token && (
                <CustomRow justifyContent="flex-end" alignItems="center">
                  <span className={classes.subtitle}>
                    {protocolData?.token?.price?.display_value}
                  </span>

                  {!!protocolData?.token?.price_percentage_change?.value && (
                    <>
                      <PaddingComponent width="4px" />
                      <ChevronDownIcon
                        className={
                          protocolData?.token?.price_percentage_change?.value >
                          0
                            ? classes.up_icon
                            : classes.down_icon
                        }
                      />
                      <PaddingComponent width="4px" />
                      <span
                        className={`${classes.valueChange} ${
                          protocolData?.token?.price_percentage_change?.value >
                          0
                            ? classes.valueChangeUp
                            : classes.valueChangeDown
                        }`}>
                        {removePlusOrMinusFromPriceChange(
                          protocolData?.token?.price_percentage_change
                            ?.display_value
                        )}
                      </span>
                    </>
                  )}
                </CustomRow>
              )}
            </CustomRow>
            <PaddingComponent height="8px" />
            <Divider />
          </CustomColumn>
        )}
        {protocolData?.description && protocolData?.main_protocol_details && (
          <CustomColumn>
            <PaddingComponent height="8px" />
            <FeedDescription
              templateString={protocolData?.description}
              mainProtocolData={{
                [protocolData?.main_protocol_details?.identifier]:
                  protocolData?.main_protocol_details,
              }}
            />
          </CustomColumn>
        )}
      </CustomColumn>
    </div>
  );
};

export default ProtocolHoverCard;
