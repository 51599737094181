import React, { useContext, useState } from "react";
import styles from "./loaderButton.module.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AuthContext } from "contextStates/AuthContext";
import { CustomRow, SpinnerComponent } from "../Components/Components";

export const TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  PRIMARY_OUTLINED: "primary_outlined",
};

const LoaderButton = ({
  children,
  className,
  onClick,
  onSuccess,
  onError,
  disabled,
  checkLogin = true,
  height = "56px",
  width,
  minWidth,
  style,
  showRefresh = true,
  buttonType = TYPES.PRIMARY,
  loadingText = "",
  loading = false,
}) => {
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);

  // Local loading state or loading prop from parent
  const isShowingLoading = isLocalLoading || loading;

  const handleClick = async () => {
    if (!isUserLoggedIn && checkLogin) {
      setShowLoginPopup(true);
      return;
    }
    setIsLocalLoading(true);
    try {
      const data = await onClick();
      setIsLocalLoading(false);
      if (onSuccess) onSuccess(data);
    } catch (err) {
      setIsLocalLoading(false);
      setIsError(true);
      if (onError) onError(err);
    }
  };

  return (
    <button
      onClick={() => {
        if (disabled || isShowingLoading) return;
        handleClick();
      }}
      style={{
        height: height,
        width: width,
        minWidth: minWidth,
        ...style,
      }}
      className={`${styles.button} ${className ? className : ""} ${
        styles[buttonType]
      } ${(disabled || isShowingLoading) && styles.disabled}`}
      disabled={disabled || isShowingLoading}>
      {isShowingLoading ? (
        <CustomRow justifyContent={"center"} flexGrow={1}>
          <SpinnerComponent size={20} color="#fff" />{" "}
          {loadingText !== "" && (
            <div style={{ marginLeft: "5px" }}>
              {loadingText || "Loading..."}
            </div>
          )}
        </CustomRow>
      ) : (
        children
      )}
      {showRefresh && isError && (
        <RefreshIcon className={styles.refresh_icon}></RefreshIcon>
      )}
    </button>
  );
};

export default LoaderButton;
