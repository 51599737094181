import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import FullPageLoader from "shared/FullPageLoader/FullPageLoader";
import Pusher from "pusher-js";
import { getSupportedChains, getStaticConfigs } from "api/profile.api";
import { v4 as uuidv4 } from "uuid";
import { CHAINS, ONE_DAY } from "utils/constants";
import { lowercaseObjectKeys } from "utils/misc";
import { usePendingTransactionPolling } from "components/V2/ActivityDetails/Transactions/transaction_utils";
import { useQuery } from "@tanstack/react-query";
import useIdleTimer from "customHooks/useIdleTimer";

export const GlobalContext = createContext({});

const GlobalContextProvider = (props) => {
  const [loading] = useState(false);
  const [supportedChainsDict, setSupportedChainsDict] = useState(null);
  const [supportedChainsLowerCaseDict, setSupportedChainsLowerCaseDict] =
    useState(null);

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: "",
    type: "",
    vertical: "bottom",
    horizontal: "left",
  });
  const [ribbonData, setRibbonData] = useState(null);
  const [featureFlags, setFeatureFlags] = useState({
    show_new_feed: true,
    show_trending_tokens: false,
    is_lens_cross_post_enabled: false,
  });
  const userPreferencesRef = useRef({});
  const activeNotifications = useRef({});
  const currentActiveTransaction = useRef(null);
  const [isEditContractPopupShown, setIsEditContractPopupShown] =
    useState(false);
  const editContractPopupDetails = useRef(null);
  // setting this opens up user x token transfer details
  const [userTokenTransferDetails, setUserTokenTransferDetails] = useState({});
  const [txnStatus, setTxnStatus] = useState({
    hash: null,
    chain: null,
    status: null,
  });
  const pusher = useMemo(
    () =>
      new Pusher("967f0dcec1810d200c24", {
        cluster: "us2",
      }),
    []
  );

  const getPusherChannel = () => {
    let pusherChannelId = localStorage.getItem("pusherChannelSessionAddressId");
    const userAddress = localStorage.getItem("userAddress");
    if (!pusherChannelId || pusherChannelId === "null") {
      pusherChannelId = userAddress ?? uuidv4();
      localStorage.setItem("pusherChannelSessionAddressId", pusherChannelId);
    } else if (
      userAddress !== pusherChannelId &&
      userAddress !== "null" &&
      userAddress !== null
    ) {
      pusherChannelId = userAddress;
      localStorage.setItem("pusherChannelSessionAddressId", pusherChannelId);
    }
    return pusher.subscribe(pusherChannelId);
  };

  const [channel] = useState(getPusherChannel());
  const brokenImagesDict = useRef({});
  const updateBrokenImagesDict = (src) => {
    brokenImagesDict.current[src] = true;
  };
  const { data: supportedChainQueryData } = useQuery({
    queryKey: ["supportedChains"],
    queryFn: getSupportedChains,
    enabled: true,
    staleTime: ONE_DAY,
  });
  const { data: staticConfigQueryData } = useQuery({
    queryKey: ["staticConfigs"],
    queryFn: getStaticConfigs,
    enabled: true,
    staleTime: ONE_DAY,
  });
  const handleErrorSnackbar = (
    err,
    customMessage = null,
    showFullError = true,
    position = { vertical: "bottom", horizontal: "left" },
    duration = 4000
  ) => {
    if (err === null || err.message !== "canceled")
      setSnackBarState({
        open: true,
        message: customMessage?.length
          ? err
            ? `${customMessage} ${showFullError ? `Error: ${err.message}` : ``}`
            : customMessage
          : err.message,
        type: "error",
        vertical: position.vertical,
        horizontal: position.horizontal,
        duration,
      });
  };

  const handleSuccessSnackbar = (
    customMessage = null,
    position = { vertical: "bottom", horizontal: "left" }
  ) => {
    if (!position.vertical) position.vertical = "bottom";
    if (!position.horizontal) position.horizontal = "left";
    setSnackBarState({
      open: true,
      message: customMessage,
      type: "success",
      vertical: position.vertical,
      horizontal: position.horizontal,
    });
  };

  useEffect(() => {
    //get supported chains from local storage first and set
    const rawSupportedChains = localStorage.getItem("supportedChains");
    if (rawSupportedChains) {
      const supportedChains = JSON.parse(rawSupportedChains);
      setSupportedChainsDict(supportedChains);
      setSupportedChainsLowerCaseDict(lowercaseObjectKeys(supportedChains));
    }

    const rawFeatureFlags = localStorage.getItem("featureFlags");
    if (rawFeatureFlags) {
      setFeatureFlags({
        ...JSON.parse(rawFeatureFlags),
      });
    }
    const rawUserPreferences = localStorage.getItem("userPreferences");
    if (rawUserPreferences) {
      userPreferencesRef.current = JSON.parse(rawUserPreferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (supportedChainQueryData) {
      setSupportedChainsDict(supportedChainQueryData);
      localStorage.setItem(
        "supportedChains",
        JSON.stringify(supportedChainQueryData)
      );
      setSupportedChainsLowerCaseDict(
        lowercaseObjectKeys(supportedChainQueryData)
      );
    }
    if (staticConfigQueryData) {
      setFeatureFlags(staticConfigQueryData);
      localStorage.setItem(
        "featureFlags",
        JSON.stringify(staticConfigQueryData)
      );
    }
  }, [supportedChainQueryData, staticConfigQueryData]);
  const getSupportedChainsListForRoutes = useCallback(() => {
    if (!!supportedChainsDict) {
      const chains = Object.keys(supportedChainsDict);
      return "(" + chains.join("|") + ")";
    }
    return CHAINS;
  }, [supportedChainsDict]);

  const [windowWidth, setWindowWidth] = useState(800);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserPreference = (key) => {
    return userPreferencesRef?.current?.[key];
  };

  const setUserPreference = (key, value) => {
    userPreferencesRef?.current
      ? (userPreferencesRef.current[key] = value)
      : (userPreferencesRef.current = { [key]: value });
    localStorage.setItem(
      "userPreferences",
      JSON.stringify(userPreferencesRef.current)
    );
  };
  const { IsTxnPending: isPolling } = usePendingTransactionPolling({
    status: txnStatus.status,
    chainId: txnStatus.chain,
    txnHash: txnStatus.hash,
    onSuccess: (s) => {
      setTxnStatus((old) => ({
        ...old,
        status: s === 1 ? "Success" : "Failed",
      }));
    },
  });
  const listenToTransaction = ({
    hash,
    chain,
    successMessage,
    errorMessage,
  }) => {
    setTxnStatus({ hash, chain, status: null, successMessage, errorMessage });
  };
  useIdleTimer({
    delay: 1000 * 60 * 30,
    onIdle: () => {
      if (channel) {
        pusher.disconnect();
      }
    },
  });

  useEffect(() => {
    if (txnStatus.hash && txnStatus.status !== null) {
      const notification = activeNotifications.current[txnStatus.hash];
      const link = `/${txnStatus.chain}/tx/${txnStatus.hash}`;
      if (notification) {
        notification.update({
          type: txnStatus.status === "Success" ? "success" : "error",
          message:
            txnStatus.status === "Success"
              ? txnStatus.successMessage
              : txnStatus.errorMessage,
          autoDismiss: 10000,
          link,
          onClick: () => {
            window.open(link, "_blank");
          },
        });
        delete activeNotifications.current[txnStatus.hash];
        setTxnStatus({ hash: null, chain: null, status: null });
      }
    }
  }, [txnStatus, isPolling]);

  return (
    <GlobalContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
        handleErrorSnackbar,
        channel,
        pusher,
        handleSuccessSnackbar,
        ribbonData,
        setRibbonData,
        supportedChainsDict,
        supportedChainsLowerCaseDict,
        getSupportedChainsListForRoutes,
        windowWidth,
        featureFlags,
        getUserPreference,
        setUserPreference,
        activeNotifications,
        editContractPopupDetails,
        isEditContractPopupShown,
        setIsEditContractPopupShown,
        userTokenTransferDetails,
        setUserTokenTransferDetails,
        currentActiveTransaction,
        listenToTransaction,
        brokenImagesDict,
        updateBrokenImagesDict,
      }}>
      {loading ? <FullPageLoader /> : props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
