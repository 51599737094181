import { signedRequest } from "api/api";

const getWeb3SocialActivityFeed =
  "/api/v4/get_web3_social_activity_feed?page_size=10";
const getConnectionActivityFeed =
  "/api/v4/get_connection_activity_feed?page_size=10";
const getTwitterNewsActivityFeed =
  "/api/v4/get_twitter_news_activity_feed?page_size=10";
const getWhaleActivityFeed = "/api/v4/get_whale_activity_feed?page_size=10";
const getZoraActivityFeed = "/api/v4/get_zora_activity_feed?page_size=10";
const getPositionActivityFeed =
  "/api/v4/get_position_activity_feed?page_size=10";
const getPostActivityFeed = "/api/v4/get_post_activity_feed?page_size=10";
const welcomePostFeed = "/api/v4/get_welcome_posts_feed?page_size=10";


export const getFeedData = async ({ pageParam }) => {
  const urls =
    typeof pageParam === "string" || pageParam == null
      ? [
          getWeb3SocialActivityFeed,
          getConnectionActivityFeed,
          getTwitterNewsActivityFeed,
          getWhaleActivityFeed,
          getZoraActivityFeed,
          getPositionActivityFeed,
          getPostActivityFeed,
          welcomePostFeed
        ]
      : pageParam;
  const requests = urls.map((url) =>
    signedRequest({
      method: "get",
      path: url,
    })
      .then((res) => res.data)
      .catch((err) => null)
  );
  const responses = await Promise.all(requests);

  // Flatten the response array
  const flatResponses = responses.reduce(
    (accumulator, currentValue) => accumulator.concat(currentValue.data),
    []
  );
  // Sort the array by the 'score' field in descending order
  const sortedResponses = flatResponses.sort((a, b) => b.score - a.score);

  //responses contauns links get a dict of all links
  const links = responses.reduce(
    (accumulator, currentValue) =>
      accumulator.concat(currentValue?.links?.next),
    []
  );

  return {
    data: sortedResponses,
    links: links,
    status: "ok",
  };
};
