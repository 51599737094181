import { shortStartingAddress, getTrimedAddress } from "utils/misc";
import classes from "./Components.module.css";
import { useState } from "react";
import Copyable from "components/ValueType/Copyable/Copyable";
import styled from "styled-components";

/* Offset component to ensure sub-items don't move when copy icon is shown */
export const OffsetDiv = styled.div`
  padding-right: 22px;
`;

const AddressComponent = ({
  textClassName,
  index,
  address,
  digitsInAddress = 6,
  copyable = true,
  padOnNoHover = false,
  displayOnHover = true,
  showTrimmed = true,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const shortAddress = showTrimmed
    ? getTrimedAddress(address)
    : shortStartingAddress(address, { digitsInAddress });

  return (
    <div
      className={classes.addressRow}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}>
      <span className={textClassName ?? classes.text}>{shortAddress}</span>
      {copyable &&
        displayOnHover &&
        (isHovering ? (
          <Copyable content={address} />
        ) : padOnNoHover ? (
          <OffsetDiv />
        ) : null)}
      {copyable && !displayOnHover && <Copyable content={address} />}
    </div>
  );
};

export default AddressComponent;
