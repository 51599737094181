import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "api/api";
import { useEffect, useMemo, useState } from "react";

export const getTransactionIndexFromId = (groupedActivity, transactionId) => {
  const transactionIndex = groupedActivity.activities.findIndex(
    (activity) => activity.id === transactionId
  );
  return transactionIndex;
};
export const getLinkForTransactions = (activityId) => {
  const url = window.location.pathname;
  const preWallets = url.split("/transactions")[0];
  const link = `${preWallets}/transactions/${activityId}/`;
  return link;
};
export const fetchTxnStatus = async ({ txnHash, chainId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/poll_txn_status`,
    bodyText: JSON.stringify({ chain_id: chainId, tx_id: txnHash }),
  });
  return response.data.data;
};

export const usePendingTransactionPolling = ({
  txnHash,
  chainId,
  status,
  onSuccess,
}) => {
  const isPending = useMemo(
    () => (!status ? true : !["Success", "Error", "Failed"].includes(status)),
    [status]
  );
  const [IsTxnPending, setIsTxnPending] = useState(true);
  const { data } = useQuery({
    queryKey: ["pending_txn_polling", txnHash],
    queryFn: () => fetchTxnStatus({ txnHash, chainId }),
    retry: 15, // Keep retrying
    retryDelay: (attempt) => (attempt < 10 ? 1000 : 1000 * 2 ** (attempt - 9)),
    enabled: isPending && !!chainId && !!txnHash && IsTxnPending,
  });
  useEffect(() => {
    if (txnHash && !status && !IsTxnPending) {
      setIsTxnPending(true);
    }
  }, [txnHash, status, IsTxnPending]);
  useEffect(() => {
    if (data) {
      onSuccess(data?.txn_status);
      setIsTxnPending(false);
    }
  }, [data, onSuccess]);

  return { IsTxnPending };
};
