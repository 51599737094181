import styled from "styled-components/macro";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { getImageNameAndType } from "components/UI/Components/UserImagesList";
import React from "react";
import ConditionalLink from "shared/ConditionalLink";
import Image from "components/UI/Image";
import classes from "./decriptionBox.module.css";
import { CustomRow } from "components/UI/Components/Components";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";

export const ProfileImagesWrapper = styled.div`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  position: relative;
  ${(props) => `
    width: ${props.width};
  `}
`;

const BoldSpanText = styled.span`
  font-weight: 500;
`;

const PreWrapText = styled.span`
  white-space: pre-wrap;
`;

const FeedDescriptionSummaryItemsList = ({
  identifiersList,
  personsLength = 0,
  profiles,
  nativeTokensInfo,
  isToken = false,
  isContract = false,
  addressPairWiseSummary,
  refetchGroupedActivity,
}) => {
  let list = [];
  const parsedPersonsLength = parseInt(personsLength);
  let extraLength = parsedPersonsLength ?? 0;

  if (!Array.isArray(identifiersList)) {
    list = identifiersList.split(",");
  } else {
    list = identifiersList;
  }

  if (list.length > 3) {
    extraLength += list.length - 3;
    list = list.slice(0, 3);
  }

  list = list.map((address) => address.trim());
  return (
    <>
      {list.map((address, index) => {
        const person = profiles[address];
        const token =
          nativeTokensInfo && person == null ? nativeTokensInfo[address] : null;
        const { image, name, imageType } = getImageNameAndType({
          token,
          isToken,
          isContract,
          person,
        });
        let displayName = name;
        if (displayName?.length > 23) {
          displayName = `${displayName.slice(0, 20)}...`;
        }
        return (
          <React.Fragment
            key={`multi-profiles-list-${index}-${identifiersList}`}>
            <ProfileHoverCard
              actor={person}
              addressPairWiseSummaryData={{
                profiles,
                person,
                nativeTokensInfo,
              }}
              onActorUpdate={refetchGroupedActivity}
              refetchGroupedActivity={refetchGroupedActivity}
              addressPairWiseSummary={
                addressPairWiseSummary
                  ? addressPairWiseSummary[person?.address]
                  : null
              }>
              <ConditionalLink
                to={person?.link}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  color: "inherit",
                  cursor: "pointer",
                }}>
                <CustomRow alignItems="center" gap="4px">
                  <Image
                    imageType={imageType}
                    alt={name}
                    className={classes.itemsImage}
                    src={image}
                  />
                  <BoldSpanText>{displayName}</BoldSpanText>
                  <VerifiedBadge profile={person} />
                </CustomRow>
              </ConditionalLink>
            </ProfileHoverCard>
            <PreWrapText>
              {/* when two items add and after first index */}
              {index === 0 && list.length === 2 && extraLength === 0 && " and "}
              {index === 0 && list.length === 2 && extraLength > 0 && ", "}
              {/* when total 3 items add comma after first */}
              {index === 0 && list.length === 3 && ", "}
              {/* when total 3 items add and after second */}
              {index === 1 && list.length === 3 && extraLength === 0 && " and "}
              {/* when more than 3 items add , after second */}
              {index === 1 && list.length === 3 && extraLength > 0 && ", "}
            </PreWrapText>
          </React.Fragment>
        );
      })}
      {extraLength > 0 && (
        <PreWrapText>
          {" +"}
          {extraLength}
          {" more"}
        </PreWrapText>
      )}
    </>
  );
};

export default FeedDescriptionSummaryItemsList;
