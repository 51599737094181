import React, { createContext, useState } from "react";
export const UserContext = createContext({});

const UserContextProvider = (props) => {
  const [autoFocusTo, setAutoFocusTo] = useState(null);
  const [frontendFollowedUsers, setFrontendFollowedUsers] = useState({});
  const getFrontendFollowedUsers = () => {
    return frontendFollowedUsers;
  };
  const addToFrontendFollowedUsers = (user) => {
    setFrontendFollowedUsers({
      ...frontendFollowedUsers,
      [user]: "follow",
    });
  };
  const removeFromFrontendFollowedUsers = (user) => {
    setFrontendFollowedUsers((old) => ({
      ...old,
      [user]: "unfollow",
    }));
  };
  return (
    <UserContext.Provider
      value={{
        autoFocusTo,
        setAutoFocusTo,
        getFrontendFollowedUsers,
        addToFrontendFollowedUsers,
        removeFromFrontendFollowedUsers,
      }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
