import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const PPLxTooltip = ({
  delay,
  background = "#23242790",
  arrowBlur = "10px",
  borderRadius = "4px",
  ...props
}) => {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      enterDelay={delay}
      enterNextDelay={delay}
      {...props}
      classes={{ popper: className }}
    />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: background,
      backdropFilter: arrowBlur,
      boxShadow: "none !important",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      background,
      backdropFilter: "blur(10px)",
      fontFamily: "Roboto",
      fontSize: "13px",
      borderRadius,
    },
  }));
  return <CustomTooltip {...props}>{props?.children}</CustomTooltip>;
};

export default PPLxTooltip;
