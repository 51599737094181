import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { CustomRow } from "components/UI/Components/Components";
import classes from "./Components.module.css";
import { Box } from "@mui/material";

export const IconTextButton = ({
  text,
  textClass,
  onClick,
  size = "18px",
  linkLike = false,
}) => {
  const color = linkLike ? "var(--primary-color)" : "var(--text-2)";
  return (
    <div className={classes.cursorDiv} onClick={onClick}>
      <CustomRow alignItems="center">
        <span className={textClass ?? classes.iconButtonText} style={{ color }}>
          {text}
        </span>
        <Box
          component={NavigateNextIcon}
          sx={{
            fontSize: size, // You can set the size with any valid CSS value
            color, // Using a MUI color from the theme
          }}
        />
      </CustomRow>
    </div>
  );
};
