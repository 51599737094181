export const TSTYLES = {
  body1: "body-1",
  body2: "body-2",
  body3: "body-3",
  title1: "title-1",
  title2: "title-2",
  secondary1: "secondary-1",
  secondary2: "secondary-2",
  secondaryTitle: "secondary-title",
  bodyLabel: "body-label",
  secondaryLabel: "secondary-label",
};

export const EMPTY_STATES = {
  POSTS: {
    light: "/svg/empty_states/no_activity_light.svg",
    dark: "/svg/empty_states/no_activity_dark.svg",
  },
  TOKEN: {
    light: "/svg/empty_states/empty_token_light.svg",
    dark: "/svg/empty_states/empty_token_dark.svg",
  },
  PROTOCOL: {
    light: "/svg/empty_states/no_protocol_light.svg",
    dark: "/svg/empty_states/no_protocol_dark.svg",
  },
  NFT: {
    light: "/svg/empty_states/no_nft_light.svg",
    dark: "/svg/empty_states/no_nft_dark.svg",
  },
  COMMENT: {
    light: "/svg/empty_states/no_comments_light.svg",
    dark: "/svg/empty_states/no_comments_dark.svg",
  },
  ERROR: {
    light: "/svg/empty_states/error_light.svg",
    dark: "/svg/empty_states/error_dark.svg",
  },
  CHART: {
    light: "/svg/empty_states/chart_light.svg",
    dark: "/svg/empty_states/chart_dark.svg",
  },
};
