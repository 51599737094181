import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import styled from "styled-components/macro";
import React, { useContext, useState } from "react";
import { produce } from "immer";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { Edit } from "@mui/icons-material";
import MethodHoverCard from "components/FeedItemV2/MethodHover/MethodHoverCard";
import ProtocolHoverCard from "components/V2/Protocol/ProtocolHover/ProtocolHoverCard";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import FeedDescriptionSumamryItemsList from "./FeedDescriptionSummaryItemsList";
import ProfileMutualFollowers from "./ProfileMutualFollowers";
import ImageNameComponent from "components/UI/Components/ImageNameComponent";

import {
  DisplayName,
  FeedDescriptionWrapper,
  MethodName,
  PersonName,
} from "../GroupFeedItem.styled";
import {
  cutStringBeyondLength,
  getChainMetaData,
  getContractNameAndLogo,
  getTrimmedString,
  stopEventPropagation,
} from "utils/misc";
import { AuthContext } from "contextStates/AuthContext";
import DescriptionBox from "./DescriptionBox";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  RedirectLink,
} from "components/UI/Components/Components";
import ConditionalLink from "shared/ConditionalLink";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import ToggleShowMore from "components/ToggleShowMore/ToggleShowMore";
import ZxText from "zxComponents/common/ZxText/ZxText";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { TSTYLES } from "zxStyles/constants";

const ChainImage = styled(Image)`
  height: 16px;
  width: auto;
  border-radius: 50%;
`;

const Chain = ({ args, profiles }) => {
  const chainId = args[1];
  const chain = getChainMetaData(chainId) || profiles[chainId];
  return (
    <>
      <ChainImage
        src={chain?.icon || chain?.logo_uri}
        alt={chain?.name ?? chainId}
        imageType={IMAGE_TYPES.CHAIN}
      />
      <PaddingComponent width="4px" />
      <span title={chainId}> {chain?.name ?? chainId}</span>
    </>
  );
};

const NonEvmAddress = ({ args, profiles }) => {
  const address = args[1];
  const profile = profiles[address];

  return (
    <a
      href={profile?.explorer_url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}>
      {profile?.display_name}
    </a>
  );
};

const Method = ({ args, methodIds, profiles }) => {
  const methodId = args[1];
  const method = methodIds?.[args[1]];
  const address = args[2];
  const profile = profiles[address];

  const [verb, setVerb] = useState(method?.display_name ?? args[1]);

  const methodUpdateCallback = (verb, methodId) => {
    setVerb(verb);
  };

  return (
    <CrowdSourcePopup
      data={{ verb, methodId, profile }}
      type="method"
      setUpdateCallback={methodUpdateCallback}>
      <MethodName>{verb}</MethodName>
    </CrowdSourcePopup>
  );
};

const TokenWrapper = styled.div`
  display: inline-flex;
  height: 16px;
  width: auto;
  font-weight: 500;
  align-self: center;
`;

const ContractTokenImageWrapper = styled.div`
  height: 16px;
  width: 16px;
  display: inline-flex;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  background-color: var(--elevation-1);
  z-index: 0;
`;

const ContractTokenImage = styled(Image)`
  height: 16px;
  width: auto;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Token = ({
  args,
  profiles,
  nativeTokensInfo,
  nftPrices,
  isNavigating = true,
  type,
  allowBubble = false,
  maxTokenLength,
}) => {
  let token =
    args && profiles
      ? type === "transaction"
        ? profiles[args]
        : profiles[args[1]]
      : null;
  let isNft = false,
    isNativeToken = false;
  let nftPrice = null;
  if (token == null) {
    const nativeToken = nativeTokensInfo ? nativeTokensInfo[args[1]] : null;
    if (nativeToken == null) {
      return null;
    }
    isNativeToken = true;
    // Extract :chain_id from /:chain_id/native_token/symbol
    // Temp hack until feed cache is evicted, safe to remove after 7/6/23
    const chainId =
      nativeToken?.token_details?.chain_id ?? nativeToken?.link.split("/")[1];
    token = {
      address: args[1],
      address_chain: chainId,
      ...nativeTokensInfo[args[1]],
    };
  } else if (token.is_token === false) {
    if (["NFT", "MultiToken"].includes(token?.address_type)) {
      isNft = true;
      nftPrice = nftPrices?.[token?.address_chain]?.[token?.address];
    }
  }
  if (token === undefined) {
    return null;
  }
  const displayPicture =
    token.token_details?.image_uri ?? token.display_picture ?? null;
  return (
    <CrowdSourcePopup
      data={token}
      disabled={!isNavigating}
      type="token"
      isNativeToken={isNativeToken}
      isNft={isNft}
      nftPrice={nftPrice}>
      <TokenWrapper onClick={(e) => !allowBubble && e.stopPropagation()}>
        {token ? (
          <ContractInfoWrapper to={isNavigating ? token.link : null}>
            <ContractTokenImageWrapper>
              <ContractTokenImage
                src={displayPicture ?? null}
                imageType={IMAGE_TYPES.SMALL_TOKEN}
                alt={""}
              />
            </ContractTokenImageWrapper>

            {type !== "transaction" && (
              <span>
                {cutStringBeyondLength({
                  text:
                    token.token_details?.symbol ||
                    token.token_symbol ||
                    token?.display_name,
                  length: maxTokenLength,
                })}
              </span>
            )}
          </ContractInfoWrapper>
        ) : (
          <span> {args[1]}</span>
        )}
      </TokenWrapper>
    </CrowdSourcePopup>
  );
};
const isSameIdentity = (person, actor, profiles) => {
  const { address } = actor ?? {};
  const actorDetails = profiles[address];
  return person?.display_name === actorDetails?.display_name;
};
const appendAddressToDisplayName = (display_name, address) => {
  if (display_name && address) {
    const shortAddress = address.substr(2, 3);

    return `${display_name}: ${shortAddress}`;
  }
};
const Person = ({
  args,
  profiles,
  identities,
  actor,
  addressPairWiseSummary,
  nativeTokensInfo,
  methodIds,
  refetchGroupedActivity,
  isPlainText = false,
  appendAddressToSameName,
  hideVerifiedTag = false,
}) => {
  const person =
    args[0] === "identity" ? identities?.[args[1]] : profiles?.[args[1]];

  const personUpdateCallback = (person) => {
    if (refetchGroupedActivity) {
      refetchGroupedActivity();
    }
  };
  if (person === undefined) {
    return <></>;
  }

  return (
    <ProfileHoverCard
      actor={person}
      addressPairWiseSummary={addressPairWiseSummary?.[args?.[1]]}
      addressPairWiseSummaryData={{
        profiles,
        actor,
        nativeTokensInfo,
        methodIds,
      }}
      onActorUpdate={personUpdateCallback}>
      <div>
        <PersonName
          to={person.identity_link || person.link}
          onClick={(e) => e.stopPropagation()}>
          {person?.display_picture && !isPlainText ? (
            <ContractTokenImageWrapper>
              <ContractTokenImage
                src={person?.display_picture}
                type={IMAGE_TYPES.SMALL_AVATAR}
                alt={""}
              />
            </ContractTokenImageWrapper>
          ) : null}
          <DisplayName>
            {actor != null &&
            isSameIdentity(person, actor, profiles) &&
            appendAddressToSameName
              ? appendAddressToDisplayName(
                  person?.display_name,
                  person?.address
                )
              : person.display_name}
          </DisplayName>
          {!hideVerifiedTag && <VerifiedBadge profile={person} />}
        </PersonName>
      </div>
    </ProfileHoverCard>
  );
};

const PopUpContent = styled.span`
  display: flex;
`;
const CrowdSourcePopup = ({
  type,
  data,
  children,
  disabled = false,
  ...innerArgs
}) => {
  if (disabled) {
    return <>{children}</>;
  }
  if (type === "contract") {
    return (
      <span>
        <ProtocolHoverCard
          protocolData={data?.protocol_details}
          contractData={data}
          {...innerArgs}>
          <PopUpContent>{children}</PopUpContent>
        </ProtocolHoverCard>
      </span>
    );
  }
  if (type === "protocol") {
    const protocolData = produce(data, (draft) => {
      if (!draft?.identifier && draft?.name) draft.identifier = draft?.name;
    });
    return (
      <span>
        <ProtocolHoverCard protocolData={protocolData} {...innerArgs}>
          <PopUpContent>{children}</PopUpContent>
        </ProtocolHoverCard>
      </span>
    );
  }
  if (type === "method") {
    return (
      <span>
        <MethodHoverCard methodData={data} {...innerArgs}>
          <PopUpContent>{children}</PopUpContent>
        </MethodHoverCard>
      </span>
    );
  }

  if (type === "token") {
    return (
      <span>
        <TokenHoverCard tokenProfile={data} {...innerArgs}>
          <PopUpContent>{children}</PopUpContent>
        </TokenHoverCard>
      </span>
    );
  }
  return null;
};
const ContractInfoWrapper = styled(ConditionalLink)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: inherit;
  font-weight: 500;
`;

const Contract = ({ args, profiles, maxTokenLength }) => {
  const contract = profiles?.[args[1]];
  if (!contract) {
    return null;
  }
  if (
    contract?.name?.toLowerCase() === "0xppl" ||
    contract?.token_details?.display_name?.toLowerCase() === "0xppl"
  ) {
    return (
      <ContractInfoWrapper
        style={{
          cursor: "text",
          textDecoration: "none",
        }}
        className="contractHover"
        onClick={(e) => stopEventPropagation(e)}>
        <ContractTokenImageWrapper>
          <ContractTokenImage
            src={getContractNameAndLogo(contract)?.logo}
            alt={`contract-image ${getContractNameAndLogo(contract)?.name}`}
            imageType={IMAGE_TYPES.SMALL_CONTRACT}
          />
        </ContractTokenImageWrapper>

        <span>{contract?.token_details?.display_name ?? contract?.name}</span>
      </ContractInfoWrapper>
    );
  }
  return (
    <CrowdSourcePopup data={contract} type="contract">
      <ContractInfoWrapper
        to={contract.protocol_details?.link ?? contract?.link}
        className="contractHover"
        onClick={(e) => e.stopPropagation()}>
        <ContractTokenImageWrapper>
          <ContractTokenImage
            src={getContractNameAndLogo(contract)?.logo}
            alt={`contract-image ${getContractNameAndLogo(contract)?.name}`}
            imageType={IMAGE_TYPES.SMALL_CONTRACT}
          />
        </ContractTokenImageWrapper>

        <span>
          {cutStringBeyondLength({
            text: getContractNameAndLogo(contract)?.name,
            length: maxTokenLength,
          })}
        </span>
      </ContractInfoWrapper>
    </CrowdSourcePopup>
  );
};

const Proposal = ({ args }) => {
  return (
    <span>
      <a href={args[1]} target="_blank" rel="noreferrer">
        Proposal
      </a>
    </span>
  );
};

const SpaceNames = ({ args }) => {
  return (
    <span>
      <a href={args[2]} target="_blank" rel="noreferrer">
        {args[1]}{" "}
      </a>
    </span>
  );
};

const MessageDiv = styled.div`
  width: 100%;
  color: var(--text-2);
  font-style: italic;
  overflow-wrap: anywhere;
`;

const Message = ({ args }) => {
  return (
    <MessageDiv className="message">
      <DescriptionBox longText={args[1]} />
    </MessageDiv>
  );
};

const NFTCollectionMention = ({ collection }) => {
  return (
    <ConditionalLink to={collection?.link}>
      <ZxFlex>
        <ContractTokenImageWrapper>
          <ContractTokenImage
            src={collection?.display_picture}
            alt={collection?.name}
            imageType={IMAGE_TYPES.NFT}
          />
        </ContractTokenImageWrapper>
        <ZxText style={TSTYLES.title1} fontSize="13px">
          {getTrimmedString(collection?.display_name, 20)}
        </ZxText>
      </ZxFlex>
    </ConditionalLink>
  );
};

// TODO make components for each of theses.
const parsers = ({
  description,
  match,
  index,
  profiles,
  methodIds,
  isAdminView,
  actor,
  nativeTokensInfo,
  addressPairWiseSummary,
  chainProfiles,
  nonEvmProfiles,
  isSuperuser,
  isGroupActivityPage,
  setSummaryPromptId,
  isHovering,
  nftPrices,
  identities,
  refetchGroupedActivity,
  mainProtocolData,
  isNavigating,
  isPlainText = false,
  fontSize,
  appendAddressToSameName,
  allowBubble = false,
  heroNames = {},
  maxTokenLength,
  hideVerifiedTag = false,
  nftCollectionMentions = null,
}) => {
  match = match.slice(2, -2);
  const params = match.split("||");
  const entity = specializeEntity(params[0], params[1], profiles);
  const key = `${match} - ${index}`;
  const identifier = params[1];
  switch (entity) {
    case "nft_collection":
      if (!nftCollectionMentions) return null;
      const collection = nftCollectionMentions[params[1]];
      if (!collection) return null;
      return <NFTCollectionMention collection={collection} />;
    case "method_id":
      return (
        <Method
          key={key}
          args={params}
          profiles={profiles}
          methodIds={methodIds}
        />
      );
    case "token":
    case "address":
    case "token_in":
    case "token_out":
      return (
        <Token
          isNavigating={isNavigating}
          key={key}
          args={params}
          profiles={profiles}
          nativeTokensInfo={nativeTokensInfo}
          nftPrices={nftPrices}
          maxTokenLength={maxTokenLength}
          allowBubble={allowBubble}
        />
      );
    case "identity":
    case "person":
      return (
        <Person
          key={key}
          args={params}
          profiles={profiles}
          identities={identities}
          methodIds={methodIds}
          actor={actor}
          addressPairWiseSummary={addressPairWiseSummary}
          nativeTokensInfo={nativeTokensInfo}
          refetchGroupedActivity={refetchGroupedActivity}
          isPlainText={isPlainText}
          hideVerifiedTag={hideVerifiedTag}
          appendAddressToSameName={appendAddressToSameName}
        />
      );
    case "contract":
      return (
        <Contract
          key={key}
          args={params}
          profiles={{ ...profiles, ...nativeTokensInfo }}
          methodIds={methodIds}
          maxTokenLength={maxTokenLength}
        />
      );
    case "proposal":
      return (
        <Proposal
          key={key}
          args={params}
          profiles={profiles}
          methodIds={methodIds}
        />
      );
    case "space_name":
      return (
        <SpaceNames
          key={key}
          args={params}
          profiles={profiles}
          methodIds={methodIds}
        />
      );
    case "message":
      return <Message key={key} args={params} />;
    case "potentially_related":
      if (isAdminView) {
        return <span key={key}> (potentially related)</span>;
      } else {
        return <></>;
      }
    case "chain":
      return <Chain key={key} args={params} profiles={chainProfiles} />;

    case "non_evm_addr":
      return (
        <NonEvmAddress key={key} args={params} profiles={nonEvmProfiles} />
      );
    case "break_line":
      return (
        <span
          key={key}
          data-newline="true"
          style={{
            whiteSpace: "pre-wrap",
            height: "8px",
            width: "100%",
          }}></span>
      );
    case "gpt_prompt":
      return !isSuperuser || !isGroupActivityPage || !isHovering ? null : (
        <Edit
          style={{
            color: "var(--primary-color)",
            cursor: "pointer",
          }}
          onClick={() => {
            setSummaryPromptId(params[1]);
          }}
          fontSize="16px"
        />
      );
    case "link":
      return (
        <RedirectLink
          key={key}
          link={params[2]}
          style={{
            color: "var(--primary-color)",
          }}>
          {params[1]}
        </RedirectLink>
      );
    case "image":
      return (
        <div style={{ width: "100%" }}>
          <Image
            key={key}
            src={params[1]}
            alt={params[2] ?? "Image"}
            imageType={IMAGE_TYPES.SMALL_NFT}
            style={{ width: "100px" }}
          />
        </div>
      );
    case "contract_list":
    case "person_list":
    case "token_list":
      return (
        <FeedDescriptionSumamryItemsList
          key={key}
          addressPairWiseSummary={addressPairWiseSummary}
          refetchGroupedActivity={refetchGroupedActivity}
          identifiersList={params[1]}
          personsLength={params[2]}
          profiles={profiles}
          nativeTokensInfo={nativeTokensInfo}
          isToken={entity === "token_list"}
          isContract={entity === "contract_list"}
        />
      );
    case "identity_list":
      return (
        <ProfileMutualFollowers
          key={key}
          addressPairWiseSummary={addressPairWiseSummary}
          refetchGroupedActivity={refetchGroupedActivity}
          identifiersList={params[1]}
          personsLength={params[2]}
          profiles={profiles}
          identities={identities}
          nativeTokensInfo={nativeTokensInfo}
          isToken={entity === "token_list"}
          isContract={entity === "contract_list"}
        />
      );
    case "main_protocol":
      return (
        <CrowdSourcePopup data={mainProtocolData[identifier]} type="protocol">
          <ImageNameComponent
            link={mainProtocolData[identifier].link}
            image={mainProtocolData[identifier].icon}
            name={mainProtocolData[identifier].name}
            size={16}
            type={IMAGE_TYPES.SMALL_CONTRACT}
            fontSize={fontSize}
          />
        </CrowdSourcePopup>
      );
    case "card_name": {
      if (!heroNames || !identifier || !Object.keys(heroNames).length) {
        return null;
      }
      const hero = heroNames?.[identifier];
      if (!hero) return <span key={key}>{identifier}</span>;
      const { display_name, image_url, link } = hero;
      return (
        <ConditionalLink to={`${link[0] === "/" ? `` : `/`}${link}`}>
          <CustomRow alignItems="center" gap="2px">
            <ContractTokenImageWrapper>
              <ContractTokenImage
                src={image_url}
                alt={`hero-card-image ${display_name}`}
                imageType={IMAGE_TYPES.SMALL_CONTRACT}
              />
            </ContractTokenImageWrapper>
            <CustomText
              text={display_name}
              fontWeight={"500"}
              fontSize="15px"
              color="var(--text-1)"
            />
          </CustomRow>
        </ConditionalLink>
      );
    }
    default:
      return <span key={key}>{match}</span>;
  }
};

const processSubstringSpaces = (word, idx, lastIndex) => {
  if (word.trim().length === 0) {
    // If word consists of only spaces, wrap them in a styled <span>
    return (
      <span
        key={`${lastIndex}-${idx}`}
        style={{ whiteSpace: "pre-wrap", width: "3px" }}></span>
    );
  } else {
    return <span key={`${lastIndex}-${idx}`}>{word}</span>;
  }
};
const FeedDescription = ({
  templateString,
  profiles,
  identities,
  methodIds,
  isAdminView,
  actor,
  nativeTokensInfo = {},
  addressPairWiseSummary = {},
  chainProfiles = {},
  nonEvmProfiles = {},
  isPairwiseRelationship = false,
  setSummaryPromptId,
  isHovering,
  nftPrices,
  fontSize = "14px",
  fontColor = "var(--text-1)",
  refetchGroupedActivity,
  mainProtocolData,
  isNavigating = true,
  isPlainText = false,
  appendAddressToSameName = true,
  isGroupedActivityDetails = false,
  allowBubble = false,
  heroNames = null,
  maxTokenLength = 23,
  hideVerifiedTag = false,
  enableSeeMore = false,
  showMoreLength = 80,
  trimExtra = false,
  nftCollectionMentions = null,
}) => {
  const containerRef = React.useRef(null);
  // TODO Extract all the parsers
  const regex = /{{([\s\S]*?)}}/g;
  let match;
  let lastIndex = 0; // Keep track of the last index we parsed
  const elements = [];
  const { isSuperuser } = useContext(AuthContext);
  const isGroupActivityPage = isGroupedActivityDetails;
  if (templateString == null) return <span />;
  do {
    match = regex.exec(templateString);
    if (match !== null) {
      const match_string = match[0];
      const index = match.index;

      // Adding condition to prevent the empty span before the description
      const substr = templateString.substring(lastIndex, index);
      if (substr?.length) {
        // Split the substr into words keeping leading/trailing spaces
        const words = substr.split(/(\s+)/);

        // Wrap each word in a separate span, including spaces
        // eslint-disable-next-line no-loop-func
        words.forEach((word, idx) => {
          elements.push(processSubstringSpaces(word, elements, lastIndex, idx));
        });
      }

      elements.push(
        parsers({
          description: templateString,
          match: match_string,
          index,
          profiles,
          methodIds,
          isAdminView,
          actor,
          nativeTokensInfo,
          addressPairWiseSummary,
          chainProfiles,
          nonEvmProfiles,
          isSuperuser,
          isGroupActivityPage,
          setSummaryPromptId,
          isHovering,
          nftPrices,
          identities,
          refetchGroupedActivity,
          mainProtocolData,
          isNavigating,
          isPlainText,
          fontSize,
          appendAddressToSameName,
          allowBubble,
          heroNames,
          maxTokenLength,
          hideVerifiedTag,
          nftCollectionMentions,
        })
      );

      lastIndex = index + match_string.length;
    }
  } while (match);
  // its noy the first character and substring is left to be added at end then add space
  if (
    lastIndex !== 0 &&
    templateString.substring(lastIndex, templateString.length).length !== 0
  ) {
    elements.push(
      processSubstringSpaces(" ", elements, lastIndex, "last-item")
    );
  }
  const words = templateString
    .substring(lastIndex, templateString.length)
    .split(/(\s+)/);
  // Wrap each word in a separate span, including spaces
  words.forEach((word, idx) => {
    elements.push(
      processSubstringSpaces(word, idx, lastIndex + 1, "last-item-word")
    );
  });
  const renderElements = () => {
    if (enableSeeMore) {
      return (
        <ToggleShowMore
          type="array"
          itemsToShow={showMoreLength}
          trimExtra={trimExtra}
          showCount={false}>
          {elements}
        </ToggleShowMore>
      );
    }
    return elements;
  };

  return (
    <FeedDescriptionWrapper
      ref={containerRef}
      onCopy={(e) => {
        const spans = containerRef.current.querySelectorAll("span");
        const textArray = Array.from(spans).map((span) => {
          const isNewLine = span.getAttribute("data-newline") === "true";
          // If the span contains a newline, add a new line after the text
          return isNewLine ? span.textContent + "\n" : span.textContent;
        });

        const spacedText = textArray.join(" "); // Join with spaces
        // Modify clipboard content
        e.clipboardData.setData("text/plain", spacedText);
        e.preventDefault(); // Prevent default copy behavior
      }}
      fontSize={isPairwiseRelationship ? "13px" : fontSize}
      fontColor={fontColor}
      isAdminView={isAdminView}
      isPairwiseRelationship={isPairwiseRelationship}>
      {renderElements()}
    </FeedDescriptionWrapper>
  );
};

const specializeEntity = (entity, potentiallyAddress, profiles) => {
  if (entity !== "address") {
    return entity;
  }

  const profile = profiles?.[potentiallyAddress];
  if (profile === undefined) {
    return entity;
  }
  const tokenOrNft =
    profile?.is_token || ["NFT", "MultiToken"].includes(profile?.address_type);
  if (tokenOrNft) {
    return "token";
  } else if (profile.address_type === "External") {
    return "person";
  } else {
    return "contract";
  }
};

export default FeedDescription;
