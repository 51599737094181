import classes from "./ContractHoverCard.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  FlexGrow,
  TypeTag,
  Divider,
  RedirectLink,
  BulletPoint,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getContractDetails } from "api/contract.api";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Box } from "@mui/material";
import { getNetworth } from "utils/profile_utils";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import Bio from "components/V2/Profile/ProfileHeader/Bio";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import EditContractDetailsButton from "../EditContractDetails/EditContractDetailsButton";
import { useQuery } from "@tanstack/react-query";
import { getTrimedAddress } from "utils/misc";
import Copyable from "components/ValueType/Copyable/Copyable";

const ContractHoverCard = ({ contractProfile, children, onOpen }) => {
  return (
    <PPLxHoverCard
      onOpen={onOpen}
      content={<ContractHoverCardContent contractProfile={contractProfile} />}>
      {children}
    </PPLxHoverCard>
  );
};

const ContractHoverCardContent = ({ contractProfile }) => {
  const networth = getNetworth(
    contractProfile,
    null,
    contractProfile?.address_chain
  );

  const { data: contractMetaData } = useQuery({
    staleTime: 1000 * 60 * 5,
    enabled:
      contractProfile?.address != null &&
      contractProfile?.address_chain !== null,
    queryKey: [
      "contract",
      contractProfile?.address_chain,
      contractProfile?.address,
    ],
    queryFn: () =>
      getContractDetails({
        chain_id: contractProfile?.address_chain,
        address: contractProfile?.address,
      }),
    retry: 2,
  });

  if (contractMetaData?.profiles?.[contractProfile?.address]) {
    contractProfile = contractMetaData?.profiles?.[contractProfile?.address];
  }

  const hasProtocol = !!contractProfile?.protocol_details?.identifier;
  const hasTVL = !!networth;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.container}>
      <CustomColumn>
        <CustomRow justifyContent="space-between">
          <RedirectLink
            link={contractMetaData?.profile?.address_link}
            className={classes.tokenIconWrapper}>
            <IconWithChain
              src={contractMetaData?.profile?.display_picture}
              alt={contractMetaData?.profile?.display_name}
              chain={contractMetaData?.profile?.address_chain}
              imageType={IMAGE_TYPES.TOKEN}
              showImageFullHeightWidth
              iconHeight="48px"
              iconWidth="48px"
              chainHeight="18px"
              chainWidth="18px"
            />
          </RedirectLink>
          <CustomRow alignItems="center">
            <CustomRow gap="8px">
              <ExternalLinks
                links={contractMetaData?.profile?.external_links}
              />
              <EditContractDetailsButton contractData={contractProfile} />
            </CustomRow>
          </CustomRow>
        </CustomRow>
        <PaddingComponent height="12px" />
        <CustomRow gap="8px">
          <ConditionalLink to={contractProfile?.address_link}>
            <div className={classes.title}>{contractProfile?.display_name}</div>
          </ConditionalLink>
        </CustomRow>

        <CustomRow>
          <FlexGrow>
            <CustomRow alignItems="center">
              <ConditionalLink to={contractProfile?.address_link}>
                <span className={classes.networth}>
                  <div>{getTrimedAddress(contractProfile?.address)}</div>
                  {contractProfile?.address && (
                    <Copyable content={contractProfile?.address} />
                  )}
                </span>
              </ConditionalLink>
              <BulletPoint />
              <span className={classes.subtitle}>
                {contractProfile?.address_chain}
              </span>
            </CustomRow>
          </FlexGrow>
          <CustomRow justifyContent="flex-end">
            <TypeTag text="Contract" />
          </CustomRow>
        </CustomRow>
        {(hasTVL || hasProtocol) && (
          <>
            <PaddingComponent height="12px" />
            <Divider />
            <PaddingComponent height="8px" />
            <CustomRow alignItems="center" flexWrap="wrap">
              {hasTVL && (
                <span className={classes.subtitle}>{`TVL ${networth}`}</span>
              )}
              {hasProtocol && (
                <>
                  {hasTVL && <BulletPoint />}
                  <span className={classes.subtitle}>Contract of</span>
                  <PaddingComponent width="6px" />
                  <ConditionalLink
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    to={contractProfile?.protocol_details?.link}
                    alignItems="center">
                    <CustomRow>
                      <Image
                        src={contractProfile?.protocol_details?.icon}
                        alt={contractProfile?.protocol_details?.name}
                        imageType={IMAGE_TYPES.TOKEN}
                        className={classes.tokenImage}
                      />
                      <PaddingComponent width="6px" />
                      <span className={classes.subtitle}>
                        {contractProfile?.protocol_details?.name}
                      </span>
                    </CustomRow>
                  </ConditionalLink>
                </>
              )}
            </CustomRow>
          </>
        )}
        {contractMetaData?.statistics?.daily_active_users_1d?.display_value && (
          <>
            <PaddingComponent height="8px" />
            <Divider />
            <PaddingComponent paddingTop="12px">
              <CustomRow alignItems="center">
                <Box
                  component={PermIdentityOutlinedIcon}
                  sx={{
                    fontSize: "18px",
                    color: "var(--text-2)",
                  }}
                />
                <PaddingComponent width="12px" />
                <span
                  className={
                    classes.subtitle2
                  }>{`Interacted by ${contractMetaData?.statistics?.daily_active_users_1d?.display_value} people in the last 24h`}</span>
              </CustomRow>
            </PaddingComponent>
          </>
        )}
        {contractMetaData?.profile?.bio && (
          <PaddingComponent paddingTop="12px">
            <Bio className={classes.bio} bio={contractMetaData?.profile?.bio} />
          </PaddingComponent>
        )}
      </CustomColumn>
    </div>
  );
};

export default ContractHoverCard;
