import React from "react";
export const ScreenerIcon = ({ size = 20, color = "var(--text-1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 256 256">
    <path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64Zm8,72h72a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Zm88,48H40a8,8,0,0,0,0,16h88a8,8,0,0,0,0-16Zm109.66,13.66a8,8,0,0,1-11.32,0L206,177.36A40,40,0,1,1,217.36,166l20.3,20.3A8,8,0,0,1,237.66,197.66ZM184,168a24,24,0,1,0-24-24A24,24,0,0,0,184,168Z"></path>
  </svg>
);

export const HammerIcon = ({ size = 20, color = "var(--text-1)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 256 256">
    <path d="M251.34,112,183.88,44.08a96.1,96.1,0,0,0-135.77,0l-.09.09L34.25,58.4A8,8,0,0,0,45.74,69.53L59.47,55.35a79.92,79.92,0,0,1,18.71-13.9L124.68,88l-96,96a16,16,0,0,0,0,22.63l20.69,20.69a16,16,0,0,0,22.63,0l96-96,32,32a16,16,0,0,0,22.63,0l28.69-28.69A16,16,0,0,0,251.34,112Zm-89,2.33L140,136.67,119.31,116l22.35-22.35a8,8,0,0,0,0-11.32L94.32,35a80,80,0,0,1,78.23,20.41l44.22,44.51L188,128.66l-14.34-14.34A8,8,0,0,0,162.34,114.32Zm49,37.66-12-12L228,111.25l12,12Z"></path>
  </svg>
);

export const QrCodeBorder = ({ size = 112, color = "var(--text-2)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 114 114"
    fill="none">
    <path
      d="M1 13V1H13"
      stroke={color}
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M113 13V1H101"
      stroke={color}
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M113 101V113H101"
      stroke={color}
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M1 101L1 113H13"
      stroke={color}
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export const XIcon = ({ color = "var(--text-1)" }) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9047 8.83384L19.3513 0.364258H17.5873L11.1187 7.71677L5.956 0.364258H0L7.80867 11.4836L0 20.3642H1.764L8.59067 12.598L14.044 20.3642H20M2.40067 1.66584H5.11067L17.586 19.1266H14.8753"
      fill={color}
    />
  </svg>
);

export const RectangularQrCodeBorder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="134"
    height="153"
    viewBox="0 0 134 153"
    fill="none">
    <path
      d="M1 13V1H13"
      stroke="var(--text-2)"
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M133 13V1H121"
      stroke="var(--text-2)"
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M133 140V152H121"
      stroke="var(--text-2)"
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M1 140L1 152H13"
      stroke="var(--text-2)"
      strokeOpacity="0.12"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export const TelegramIconFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none">
    <path
      d="M8.1228 13.6039L9.5465 17.5445C9.5465 17.5445 9.7245 17.9132 9.9151 17.9132C10.1057 17.9132 12.9406 14.964 12.9406 14.964L16.0931 8.875L8.1736 12.5867L8.1228 13.6039Z"
      fill="#C8DAEA"
    />
    <path
      d="M10.0105 14.6152L9.73721 17.5198C9.73721 17.5198 9.62281 18.4098 10.5126 17.5198C11.4024 16.6298 12.2541 15.9435 12.2541 15.9435"
      fill="#A9C6D8"
    />
    <path
      d="M8.14871 13.7448L5.22011 12.7906C5.22011 12.7906 4.87011 12.6486 4.98281 12.3266C5.00601 12.2602 5.05281 12.2037 5.19281 12.1066C5.84171 11.6543 17.2034 7.57064 17.2034 7.57064C17.2034 7.57064 17.5242 7.46254 17.7134 7.53444C17.7602 7.54893 17.8023 7.57559 17.8355 7.6117C17.8686 7.64781 17.8915 7.69206 17.9019 7.73994C17.9224 7.82451 17.9309 7.91151 17.9273 7.99844C17.9264 8.07364 17.9173 8.14334 17.9104 8.25264C17.8412 9.36914 15.7704 17.7019 15.7704 17.7019C15.7704 17.7019 15.6465 18.1895 15.2026 18.2062C15.0935 18.2098 14.9848 18.1913 14.883 18.1519C14.7812 18.1125 14.6884 18.0531 14.6101 17.977C13.739 17.2277 10.7282 15.2043 10.0629 14.7593C10.0479 14.7491 10.0353 14.7358 10.0258 14.7203C10.0164 14.7047 10.0104 14.6874 10.0083 14.6693C9.99901 14.6224 10.05 14.5643 10.05 14.5643C10.05 14.5643 15.2926 9.90434 15.4321 9.41514C15.4429 9.37724 15.4021 9.35854 15.3473 9.37514C14.9991 9.50324 8.96291 13.3151 8.29671 13.7358C8.24875 13.7504 8.19807 13.7534 8.14871 13.7448Z"
      fill="white"
    />
  </svg>
);

export const ThreadConnectorIcon = () => {
  return (
    <svg
      width="12"
      height="35"
      viewBox="0 0 15 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M14 0.000305176V12.8817C14 14.9156 14 18.9834 10.5789 21.6952C6.30261 25.085 1 23.0512 1 19.6613C1 16.2715 4.87802 15.0967 7.84211 16.2715C11.2632 17.6274 14 22.3732 14 26.441C14 29.6952 14 36.8365 14 40.0003"
        stroke="var(--text-1)"
        strokeOpacity="0.6"
        strokeWidth="2"
      />
    </svg>
  );
};
