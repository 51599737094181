import appLogo from "assets/svg/Landing/logo.svg";
import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}>
      <img src={appLogo} alt="loader" className={classes.loader} />
    </div>
  );
};

export default Loader;
