// adapted from @web3-onboard/coinbase

import * as CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import icon from "./icon.js";

function coinbaseWallet({ darkMode = false } = {}) {
  return () => {
    return {
      label: "Coinbase Wallet",
      getIcon: async () => icon,
      getInterface: async ({ chains, appMetadata }) => {
        const [chain] = chains;
        const { name, icon } = appMetadata || {};
        // according to https://github.com/wagmi-dev/wagmi/issues/383
        // @coinbase/wallet-sdk export double default fields
        // so we need to detect it to get the real constructor
        const CoinbaseWalletSDKConstructor =
          CoinbaseWalletSDK.CoinbaseWalletSDK;
        const base64 = window.btoa(icon || "");
        const appLogoUrl = `data:image/svg+xml;base64,${base64}`;
        const instance = new CoinbaseWalletSDKConstructor({
          appName: name || "",
          appLogoUrl,
          darkMode,
        });
        const coinbaseWalletProvider = instance.makeWeb3Provider(
          chain.rpcUrl,
          parseInt(chain.id)
        );
        // patch the chainChanged event
        const on = coinbaseWalletProvider.on.bind(coinbaseWalletProvider);
        coinbaseWalletProvider.on = (event, listener) => {
          on(event, (val) => {
            if (event === "chainChanged") {
              listener(`0x${val.toString(16)}`);
              return;
            }
            listener(val);
          });
          return coinbaseWalletProvider;
        };
        return {
          provider: coinbaseWalletProvider,
          instance,
        };
      },
    };
  };
}

export default coinbaseWallet;
