class ChatConnectionManager {
  shouldBeConnected = false;
  connected = false;
  inProgress = false;
  connectTimeoutId = null;
  disconnectTimeoutId = null;
  userToConnect = null;
  userToken = null;
  client = null;
  debugMode = false;
  onConnectCallback = () => {};

  constructor(client) {
    this.client = client;
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    window.addEventListener("blur", this.disconnect);
    window.addEventListener("focus", this.connect);
  }

  connect() {
    this.setConnectionStatus(true);
  }

  disconnect() {
    this.setConnectionStatus(false);
  }

  log(...args) {
    if (this.debugMode) {
      console.log(...args);
    }
  }

  setToken(userToConnect, userToken) {
    this.userToConnect = userToConnect;
    this.userToken = userToken;
  }

  setOnConnectCallback(callback) {
    this.onConnectCallback = callback;
  }

  setConnectionStatus(status) {
    clearTimeout(this.connectTimeoutId);
    clearTimeout(this.disconnectTimeoutId);
    this.log("Connection status should be", status);
    this.shouldBeConnected = status;
    // connection try immediately in 100ms
    this.connectTimeoutId = setTimeout(() => {
      if (
        this.shouldBeConnected &&
        !this.connected &&
        this.userToConnect &&
        this.userToken
      ) {
        if (this.inProgress) {
          this.log("postponing connection");
          this.setConnectionStatus(true);
        } else {
          this.inProgress = true;
          this.log("connecting user");
          this.client
            .connectUser(this.userToConnect, this.userToken)
            .then((resp) => {
              this.connected = true;
              this.inProgress = false;
              this.onConnectCallback(resp);
              this.client.on(this.onConnectCallback);
              this.log("connected user");
            })
            .catch((e) => {
              this.inProgress = false;
            });
        }
      }
    }, 100);

    // disconnection try immediately after 10s, if user is not active within 10 seconds
    this.disconnectTimeoutId = setTimeout(() => {
      const isOnMessagingTab = window.location.pathname.includes("/messages");
      if (
        !isOnMessagingTab &&
        !this.shouldBeConnected &&
        this.connected &&
        !this.inProgress
      ) {
        if (this.inProgress) {
          this.log("postponing disconnection");
          this.setConnectionStatus(false);
        } else {
          this.inProgress = true;
          this.log("disconnecting user");
          this.client.off(this.onConnectCallback);
          this.client
            .disconnectUser()
            .then(() => {
              this.connected = false;
              this.inProgress = false;
              this.log("disconnected user");
            })
            .catch((e) => {
              this.inProgress = false;
            });
        }
      }
    }, 10000);
  }
  destroy() {
    clearTimeout(this.connectTimeoutId);
    clearTimeout(this.disconnectTimeoutId);
    window.removeEventListener("blur", this.disconnect);
    window.removeEventListener("focus", this.connect);
    this.client.off(this.onConnectCallback);
    this.client.disconnectUser().then(() => {
      this.connected = false;
      this.inProgress = false;
      this.log("disconnected user");
    });
  }
}

const instance = null;
export const getInstance = (client) => {
  if (!instance) {
    return new ChatConnectionManager(client);
  } else if (instance.client === client) {
    return instance;
  } else {
    instance.destroy();
    return new ChatConnectionManager(client);
  }
};
