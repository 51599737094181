import {
  PaddingComponent,
  CustomTextSpan,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import ConditionalLink from "shared/ConditionalLink";
import styled from "styled-components/macro";

const ImageNameWrapper = styled(ConditionalLink)`
  display: inline-flex;
  align-items: center;
  gap: 2px;
`;
const ImageNameComponent = ({
  image,
  link,
  name,
  imageType,
  size,
  fontSize = "13px",
}) => {
  return (
    <ImageNameWrapper
      to={link}
      className="contractHover"
      onClick={(e) => e.stopPropagation()}>
      <Image
        src={image}
        alt={name}
        style={{
          height: size,
          width: size,
          borderRadius: "50%",
        }}
        imageType={imageType}
      />
      <PaddingComponent width="2px" />
      <CustomTextSpan fontSize={fontSize} fontWeight="500">
        {name}
      </CustomTextSpan>
    </ImageNameWrapper>
  );
};

export default ImageNameComponent;
