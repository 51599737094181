import { signedRequest } from "api/api";
import { useQuery } from "@tanstack/react-query";

const fetchTokenMetaData = async (address, chain_id) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_token_metadata",
    bodyText: JSON.stringify({
      token_address: address,
      chain_id,
    }),
  });

  return resp?.data?.data;
};

const useFetchTokenMetaData = (address, chain_id, enabled = true) => {
  const tokenMetaDataQuery = useQuery({
    queryKey: ["token_metadata", address, chain_id],
    queryFn: () => fetchTokenMetaData(address, chain_id),
    enabled: enabled && !!address && !!chain_id,
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  return tokenMetaDataQuery;
};

export default useFetchTokenMetaData;
