import { useMemo } from "react";
import Copyable from "components/ValueType/Copyable/Copyable";

const OverflowText = ({ text, limit = 17 }) => {
  const overflowText = useMemo(
    () => (text == null ? "" : text.substring(0, limit) + "..."),
    [text, limit]
  );

  if (text != null && text.length <= limit) {
    return (
      <Copyable content={text} showOnHover>
        {text}
      </Copyable>
    );
  }
  return (
    <Copyable content={text} showOnHover>
      <span title={text}>{overflowText}</span>
    </Copyable>
  );
};

export default OverflowText;
