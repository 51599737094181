import { getProfileData } from "api/profile.api";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const useProfileDetails = ({ identifier }) => {
  const queryKey = ["get_profile_details", identifier];
  const { data, isError, isLoading, isSuccess, refetch, isFetching } = useQuery(
    {
      queryKey: queryKey,
      queryFn: () => getProfileData({ identifier }),
      staleTime: 1000 * 60 * 5,
      enabled: identifier != null,
      retry: 2,
    }
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      if (
        typeof identifier === "string" &&
        data?.data?.data?.type === "address"
      ) {
        // original identifier was address, update identity query as well
        const id = data?.data?.data?.details?.identity_id;
        if (id) {
          queryClient.setQueryData(["get_profile_details", id], data);
        }
      }
    }
  }, [isSuccess, queryClient, data, identifier]);

  return { data, isError, isLoading, refetch, isSuccess, isFetching };
};

export default useProfileDetails;
