import { useCallback, useEffect, useState } from "react";

const useCopyToClipboard = (content) => {
  const [copyStatus, setCopyStatus] = useState(false);
  const [copyableText, setCopyableText] = useState(
    typeof content === "object" ? JSON.stringify(content) : content
  );
  useEffect(() => {
    setCopyableText(content);
  }, [content]);

  useEffect(() => {
    let timeoutId;
    if (copyStatus) {
      timeoutId = setTimeout(() => setCopyStatus(false), 1000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [copyStatus]);

  const copy = useCallback(async () => {
    let isCopied = false;

    if ("clipboard" in navigator) {
      try {
        isCopied = await navigator.clipboard.writeText(copyableText);
        setCopyStatus(true);
      } catch (err) {
        setCopyStatus(false);
      }
    } else {
      // Internet Explorer
      isCopied = document.execCommand("copy", true, copyableText);
      setCopyStatus(isCopied);
    }
  }, [copyableText]);

  return [copyStatus, copy];
};

export default useCopyToClipboard;
