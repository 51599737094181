import classes from "./AddToExistingProfileForm.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTextField from "../CustomTextField";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getSearchResults } from "api/search.api";
import SearchItem from "./SearchItem";
import { CircularProgress } from "@mui/material";

const AddToExistingProfileForm = ({ onBack, onAddToBundle }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timeOutId;
    const controller = new AbortController();

    if (searchTerm.trim().length > 0) {
      setSearchResults([]);
      setIsLoading(true);
      timeOutId = setTimeout(() => {
        getSearchResults({
          queryKey: ["follow_search", `${searchTerm} source:identity `],
          signal: controller.signal,
        })
          .then((data) => {
            setSearchResults(data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }, 500);
    }

    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [searchTerm]);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.add_to_existing_profile_form}>
      <div className={classes.header}>
        <div onClick={onBack} className={classes.icon}>
          <ArrowBackIcon color="inherit" fontSize="small" />
        </div>
        <div className={classes.title}>Add Wallet to Existing Profile</div>
      </div>
      <CustomTextField
        size="small"
        placeholder="Search for a profile"
        fullWidth
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <div className={classes.search_icon}>
              {isLoading ? (
                <CircularProgress size={12} color="inherit" />
              ) : (
                <SearchIcon size="small" color="inherit" />
              )}
            </div>
          ),
        }}
      />

      {searchTerm?.length > 0 && !isLoading && (
        <div className={classes.profiles}>
          PROFILES {searchResults?.length && `(${searchResults?.length})`}
        </div>
      )}

      <div className={classes.search_results}>
        {searchResults.map((result) => {
          return (
            <SearchItem searchResult={result} onAddToBundle={onAddToBundle} />
          );
        })}
      </div>
    </div>
  );
};

export default AddToExistingProfileForm;
