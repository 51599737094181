import React, { useState, useMemo } from "react";
import classes from "./toggleShowMore.module.css";

const ArrayShowMore = ({
  children,
  itemsToShow,
  hideToggle = false,
  buttonColor = "var(--text-2)",
  showCount = true,
  trimExtra = false,
}) => {
  const [showMore, setShowMore] = useState(false);
  const childrenCount = React.Children.count(children);
  const displayedActivities = useMemo(
    () =>
      showMore
        ? children
        : React.Children.toArray(children).slice(0, itemsToShow),
    [children, itemsToShow, showMore]
  );

  let toggleShowMore = null;

  if (!hideToggle) {
    toggleShowMore =
      childrenCount > itemsToShow ? (
        showMore ? null : !trimExtra ? (
          <button
            className={classes.showMore}
            onClick={(e) => {
              e.stopPropagation();
              setShowMore(true);
            }}
            style={{ color: buttonColor }}>
            Show More {showCount && `(+${childrenCount - itemsToShow} More)`}
          </button>
        ) : null
      ) : null;
  }

  return (
    <>
      {displayedActivities}{" "}
      {displayedActivities.length < childrenCount && "..."}
      {toggleShowMore}
    </>
  );
};

const TextShowMore = ({ text, limit, className, textClassName }) => {
  const [showMore, setShowMore] = useState(false);

  const displayedText = useMemo(
    () => (showMore ? text : text.slice(0, limit)),
    [text, limit, showMore]
  );
  return (
    <span className={textClassName}>
      {displayedText} {displayedText.length < text.length && "..."}
      {text.length > limit && (
        <button
          onClick={() => setShowMore(!showMore)}
          className={`${classes.showMore} ${className}`}>
          {showMore ? "Show Less" : "Show More"}
        </button>
      )}
    </span>
  );
};

const ToggleShowMore = ({ type = "array", ...props }) => {
  return type === "array" ? (
    <ArrayShowMore {...props} />
  ) : (
    <TextShowMore {...props} />
  );
};

export default ToggleShowMore;
