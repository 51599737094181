import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import classes from "./UserImagesList.module.css";
import React from "react";

export const UserImagesList = ({
  profiles,
  size,
  isToken = false,
  isContract = false,
  nativeTokensInfo,
  addresses,
}) => {
  return addresses?.map((address, index) => {
    const person = profiles[address];
    const token =
      nativeTokensInfo && person == null ? nativeTokensInfo[address] : null;
    const { image, name, imageType } = getImageNameAndType({
      person,
      token,
      isToken,
      isContract,
    });
    return (
      <React.Fragment key={`user-image-${address}-${index}`}>
        <ProfileHoverCard actor={person}>
          <Image
            style={{
              width: index === 0 ? `${size}px` : `${size + 1}px`,
              height: index === 0 ? `${size}px` : `${size + 1}px`,
              zIndex: index + 1,
              marginLeft:
                index === 0
                  ? "0px"
                  : `${(index === 1 ? size - 3 : size - 2) * index}px`,
              position: index === 0 ? "relative" : "absolute",
            }}
            imageType={imageType}
            alt={name}
            className={`${
              index === 0 ? classes.profileInitial : classes.profileSeries
            }`}
            src={image}></Image>
        </ProfileHoverCard>
      </React.Fragment>
    );
  });
};

export const getImageNameAndType = ({ person, token, isToken, isContract }) => {
  const name = !isToken
    ? person?.display_name
    : token != null
    ? token?.symbol ?? token?.token_details?.symbol
    : person?.token_details?.symbol ?? person?.token_details?.display_name;
  const image = !isToken
    ? person?.display_picture
    : token != null
    ? token?.display_picture ?? token?.token_details?.logo
    : person?.display_picture;

  let imageType = IMAGE_TYPES.SMALL_AVATAR;

  if (isToken) {
    imageType = IMAGE_TYPES.SMALL_TOKEN;
  } else if (isContract) {
    imageType = IMAGE_TYPES.SMALL_CONTRACT;
  }
  return {
    imageType,
    name,
    image,
  };
};
