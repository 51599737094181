import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { SealCheck } from "@phosphor-icons/react";

const VerifiedBadge = ({
  profile,
  size,
  showTooltip = false,
  isRenderOnly = false,
  color = "var(--primary-color)",
}) => {
  if (isRenderOnly) {
    return <SealCheck size={size} weight="fill" fill={color} />;
  }
  if (
    profile?.is_user_account ||
    profile?.user_since != null ||
    profile?.is_verified ||
    profile?.onboarding_state === "complete"
  ) {
    if (showTooltip) {
      return (
        <PPLxTooltip title="Active on 0xPPL" arrow delay={500}>
          <SealCheck size={size} weight="fill" fill="var(--primary-color)" />
        </PPLxTooltip>
      );
    } else
      return (
        <SealCheck size={size} weight="fill" fill="var(--primary-color)" />
      );
  }

  return null;
};

export default VerifiedBadge;
