import { signedApiRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import { useContext, useState } from "react";
import { IconTextButton } from "components/UI/Components/IconTextButton";
import { CursorDiv } from "components/UI/Components/Components";
const BundleButton = ({ identityId, address, onSuccess }) => {
  const [isBundling, setIsBundling] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);

  if (identityId == null) {
    // Cannot bundle addresses with addresses today
    return null;
  }

  const onBundle = () => {
    setIsBundling(true);
    const data = {
      identity_id: identityId,
      add_addresses: [
        {
          reason: "",
          address,
        },
      ],
    };

    signedApiRequest({
      method: "post",
      path: "add_remove_addresses",
      bodyText: JSON.stringify(data),
    })
      .then((data) => {
        if (onSuccess) {
          onSuccess(data);
        }

        setSnackBarState({
          open: true,
          message: "Successfully Bundled",
          type: "success",
        });
      })
      .catch((error) => {
        setIsBundling(false);
        handleErrorSnackbar(error, "Failed to Bundle");
      });
  };
  return (
    <CursorDiv>
      <IconTextButton
        text={isBundling ? "Bundling" : "Bundle"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isBundling) return;
          onBundle();
        }}
      />
    </CursorDiv>
  );
};

export default BundleButton;
