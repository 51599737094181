import { useState, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import classes from "./decriptionBox.module.css";
import { PaddingComponent } from "components/UI/Components/Components";

const DescriptionBox = ({ longText, limit = 350 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const needExpanded = longText.length > limit;
  const text = useMemo(
    () =>
      !needExpanded || isExpanded
        ? longText
        : longText.substring(0, limit) + "...",
    [needExpanded, isExpanded, longText, limit]
  );

  return (
    <div>
      <PaddingComponent height="12px" />
      <ReactMarkdown
        className={classes.descriptionBox}
        remarkPlugins={[remarkGfm]}
        components={{
          // a tag gets relevant props passed in, the warning below is incorrect
          a: (props) => (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              {...props}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ),
          img: (props) => (
            <img
              {...props}
              style={{ maxWidth: "100%", ...props.style }}
              alt=""
            />
          ),
        }}>
        {text}
      </ReactMarkdown>
      {needExpanded && (
        <span className={classes.expand}>
          {isExpanded ? (
            <span
              onClick={(e) => {
                setIsExpanded(false);
                e.preventDefault();
                e.stopPropagation();
              }}
              className="seeLess">
              {" "}
              See Less
            </span>
          ) : (
            <span
              onClick={(e) => {
                setIsExpanded(true);
                e.preventDefault();
                e.stopPropagation();
              }}>
              {" "}
              See More
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default DescriptionBox;
