import { renderText } from "stream-chat-react";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";

const CustomMentionComponent = ({ children, node: { mentionedUser } }) => {
  return (
    <ProfileHoverCard
      actor={{
        identity_id: mentionedUser.id.replace("sc-identity-", ""),
      }}>
      <span
        style={{
          cursor: "default",
          color: "var(--primary-color)",
        }}>
        {children}
      </span>
    </ProfileHoverCard>
  );
};

export const replaceMentionedUsers = (text, mentionedUsers) => {
  let updatedText = text;
  if (mentionedUsers?.length) {
    mentionedUsers.forEach((user) => {
      updatedText = updatedText.replace(
        new RegExp(`@${user.id}`, "g"),
        `@${user.name} `
      );
    });
  }

  return updatedText;
};

export const customRenderText = (text, mentionedUsers) => {
  const updatedText = replaceMentionedUsers(text, mentionedUsers);
  return renderText(updatedText, mentionedUsers, {
    customMarkDownRenderers: {
      mention: CustomMentionComponent,
    },
  });
};
export const ChannelTypes = {
  DM: "DM",
  GROUP: "Group",
  STREAMING_GROUP: "Streaming_group",
};
export let groupChannelTypes = [ChannelTypes.GROUP];
export const isGroupChannelType = (channelType) =>
  groupChannelTypes.includes(channelType);
export const isStreamingGroupChannelType = (channelType) =>
  channelType === ChannelTypes.STREAMING_GROUP;
export const isEnabledChannelType = (channelType) =>
  getAllChannelTypes().includes(channelType);
export const getAllChannelTypes = () => {
  return [ChannelTypes.DM, ...groupChannelTypes];
};

export const setGroupChannelTypes = (types) => {
  groupChannelTypes = types;
};
export const tabToChannelTypesMap = (tab) => {
  switch (tab) {
    case "dms":
      return [ChannelTypes.DM];
    case "groups":
      return groupChannelTypes;
    case "all":
    default:
      return [ChannelTypes.DM, ...groupChannelTypes];
  }
};
