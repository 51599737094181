import errorOutline from "assets/svg/error_outline.svg";
import successTick from "assets/svg/success_tick.svg";
import styles from "./Adornment.module.css";

const Adornment = ({ options, showAvailable = false }) => {
  return (
    <div className={styles.adornment}>
      {options.available === null ? null : options.available === false ? (
        <div className={styles.alignment}>
          <img
            className={styles.not_available}
            src={errorOutline}
            alt="error"
          />
        </div>
      ) : (
        <span className={styles.available}>
          {showAvailable && "Available"}
          <img
            src={successTick}
            alt="success"
            className={styles.circle_check}
            fontSize="small"
          />
        </span>
      )}
    </div>
  );
};

export default Adornment;
