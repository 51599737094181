import * as Sentry from "@sentry/react";

export const logSentryEvent = (err, properties = {}, type = "error") => {
  try {
    if (type === "error") {
      Sentry.captureException(err, {
        extra: { properties, type },
      });
    } else {
      Sentry.captureMessage(err, {
        extra: { properties, type },
      });
    }
  } catch (err) {
    console.error("Could not log event to Sentry", err);
    return;
  }
};
