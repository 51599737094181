import React, { createContext, useState } from "react";
export const LeftSideHeaderContext = createContext({});

const LeftSideHeaderContextProvider = (props) => {
  const [isDevToolsPopUpOpen, setIsDevToolsPopUpOpen] = useState(false);
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState(null);
  const [title, setTitle] = useState("");
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

  return (
    <LeftSideHeaderContext.Provider
      value={{
        setIsDevToolsPopUpOpen,
        isDevToolsPopUpOpen,
        isLeftSideBarOpen,
        setIsLeftSideBarOpen,
        title,
        setTitle,
        isSearchInputFocused,
        setIsSearchInputFocused,
      }}>
      {props.children}
    </LeftSideHeaderContext.Provider>
  );
};

export default LeftSideHeaderContextProvider;
