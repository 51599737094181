import Image from "components/UI/Image";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { useState } from "react";
import classes from "./SearchItem.module.css";

const SearchItem = ({ searchResult, onAddToBundle }) => {
  const { data } = searchResult;
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${classes.search_item} ${
        open ? classes.search_item_expanded : ""
      }`}
      key={data.id}
    >
      <div
        className={classes.header}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Image
          className={classes.display_picture}
          src={data.display_picture}
          alt={data.title}
        />
        <div className={classes.title}>{data.title}</div>
      </div>
      {open && (
        <div>
          <LoaderButton
            onClick={() => {
              return onAddToBundle(data.id);
            }}
            className={classes.button}
          >
            Add Address to Profile
          </LoaderButton>
        </div>
      )}
    </div>
  );
};

export default SearchItem;
