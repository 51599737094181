import {
  CustomRow,
  CustomText,
  IconWrapper,
} from "components/UI/Components/Components";
import GitHubIcon from "@mui/icons-material/GitHub";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box } from "@mui/material";
import { lensIcon, farcasterIcon } from "utils/assets";
import classes from "./addSocialPlatformsButton.module.css";
import React from "react";
const platforms = ["github", "telegram", "linkedin", "lens", "farcaster"];

const icons = {
  linkedin: LinkedInIcon,
  telegram: TelegramIcon,
  github: GitHubIcon,
  lens: lensIcon,
  farcaster: farcasterIcon,
};

const AddSocialPlatformsButton = ({
  visiblePlatforms,
  setVisiblePlatforms,
}) => {
  if (visiblePlatforms.length === platforms.length) {
    return null;
  }
  return (
    <CustomRow gap="16px" alignItems="center">
      <CustomText text="Add more:" />
      <CustomRow gap="8px">
        {platforms.map(
          (platform) =>
            !visiblePlatforms.includes(platform) && (
              <PlatformButton
                key={platform}
                platform={platform}
                visiblePlatforms={visiblePlatforms}
                setVisiblePlatforms={setVisiblePlatforms}
              />
            )
        )}
      </CustomRow>
    </CustomRow>
  );
};

const PlatformButton = React.memo(
  ({ platform, visiblePlatforms, setVisiblePlatforms }) => {
    const handleClick = () => {
      setVisiblePlatforms([...visiblePlatforms, platform]);
    };

    if (!platform) {
      return null;
    }
    return (
      <IconWrapper onClick={handleClick} width="24px" height="24px">
        {["lens", "farcaster"]?.includes(platform) ? (
          <img
            src={icons?.[platform]}
            className={classes.platformIcon}
            alt={`${icons?.[platform]}-icon`}
          />
        ) : (
          <Box component={icons?.[platform]} className={classes.platformIcon} />
        )}
      </IconWrapper>
    );
  }
);

export default AddSocialPlatformsButton;
