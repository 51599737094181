import classes from "./basicInfoRowTile.module.css";

const BasicInfoRowTile = ({
  title,
  value,
  children,
  titleFlexGrow = 1,
  valueFlexGrow = 3,
}) => {
  return (
    <div className={classes.basicInfoRowTile}>
      <span
        className={classes.basicInfoTitle}
        style={{ flexGrow: titleFlexGrow }}>
        {title}
      </span>
      {value ? (
        <span
          className={classes.basicInfoValue}
          style={{ flexGrow: valueFlexGrow }}>
          {value}
        </span>
      ) : (
        children
      )}
    </div>
  );
};

export default BasicInfoRowTile;
