const { signedRequest } = require("./api");

export const getSearchResults = async ({
  queryKey,
  signal,
  endpoint = "api/search/search",
}) => {
  const [, searchTerm, filters] = queryKey;
  var searchTermModified = searchTerm?.trim();
  filters?.map((filter) => {
    if (filter.isSelected && filter.name !== "All") {
      searchTermModified += ` source:${filter.name}`;
    }
    return searchTermModified;
  });
  if (searchTerm.length) {
    const resp = await signedRequest({
      path: `${endpoint}?query=${searchTermModified}`,
      method: "get",
      signal,
    });
    return resp.data.data;
  }
  return [];
};

export const getSearchFilters = async ({ endpoint = "api/search/search" }) => {
  return signedRequest({
    path: `/api/search/filters`,
    method: "post",
  });
};

export const getTwitterSearchResults = async ({
  queryKey,
  signal,
  endpoint = "api/search/search_twitter",
}) => {
  const [, searchTerm] = queryKey;

  if (searchTerm.length) {
    const resp = await signedRequest({
      path: `${endpoint}?query=${searchTerm?.trim()}`,
      method: "get",
      signal,
    });
    return resp.data.data;
  }
  return [];
};

export const getCoingeckoSearchResults = async ({ queryKey, signal }) => {
  const [, searchTerm] = queryKey;

  if (searchTerm.length) {
    const response = await signedRequest({
      path: `/api/search/search_for_watchlist?query=${searchTerm}`,
      method: "get",
      signal,
    });
    return response.data.data;
  }
  return [];
};
