import classes from "./AddToExistingProfile.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const AddToExistingProfile = ({ onClick }) => {
  return (
    <div className={classes.add_to_existing_profile} onClick={onClick}>
      <div className={classes.text}>Add to existing profile</div>
      <div className={classes.icon}>
        <ChevronRightIcon color="inherit" />
      </div>
    </div>
  );
};

export default AddToExistingProfile;
