import React, { createContext, useContext, useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { AuthContext } from "./AuthContext";

import { getAllChannelTypes } from "../components/V2/Messaging/helpers";
import { getInstance } from "./ChatConnectionManager";

const apiKey = process.env["REACT_APP_STREAM_KEY"];

export const MessagingContext = createContext({});

const client = StreamChat.getInstance(apiKey, {
  enableInsights: true,
  enableWSFallback: true,
});

const chatConnectionManager = getInstance(client);

const fetchAndReturnUnreadCount = async () => {
  if (client.user) {
    const allAcceptedChannels = await client.queryChannels({
      type: "messaging",
      members: { $in: [client.userID] },
      channel_type: { $in: getAllChannelTypes() },
      invite: "accepted",
    });
    return allAcceptedChannels.reduce((acc, channel) => {
      return acc + channel.state.unreadCount;
    }, 0);
  }
  return null;
};

const MessagingContextProvider = (props) => {
  const { identityDetails, isUserLoggedIn } = useContext(AuthContext);
  const [totalUnreadCount, setTotalUnreadCount] = useState(null);
  const [showAddGroupPanel, setShowAddGroupPanel] = useState(false);
  const [chatUser, setChatUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const updateUserLoading = (loading) => {
    setIsUserLoading(loading);
  };
  const clientEventCallBack = (event) => {
    if (event?.total_unread_count != null) {
      fetchAndReturnUnreadCount().then((unreadCount) => {
        if (unreadCount !== null) {
          setTotalUnreadCount(unreadCount);
        }
      });
    }
  };
  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    const identity = identityDetails.current?.identity;
    const userToConnect = {
      id: identity?.stream_chat_details?.id,
      name: identity?.display_name,
      image: identity?.display_picture,
    };
    const userToken = identity?.stream_chat_details?.session_token;
    if (!userToken) {
      return;
    }

    chatConnectionManager.setToken(userToConnect, userToken);
    chatConnectionManager.setOnConnectCallback((resp) => {
      setChatUser(client.user);
      clientEventCallBack(resp);
    });
    chatConnectionManager.setConnectionStatus(true);
    return () => {
      chatConnectionManager.setConnectionStatus(false);
    };
  }, [isUserLoggedIn, identityDetails]);

  return (
    <MessagingContext.Provider
      value={{
        chatClient: client,
        chatUser,
        totalUnreadCount,
        showAddGroupPanel,
        setShowAddGroupPanel,
        updateUserLoading,
        isUserLoading,
      }}>
      {props.children}
    </MessagingContext.Provider>
  );
};

export default MessagingContextProvider;
