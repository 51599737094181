import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const PPLxHoverCard = ({
  content,
  children,
  placement = "bottom-start",
  delay = 500,
  minWidth,
  ...rest
}) => {
  return (
    <LightTooltipWithRef
      enterDelay={delay}
      enterNextDelay={delay}
      title={content}
      arrow
      placement={placement}
      minWidth={minWidth}
      {...rest}>
      {children}
    </LightTooltipWithRef>
  );
};

const LightTooltip = styled(({ className, minWidth, ...rest }) => (
  <Tooltip
    disableFocusListener={true}
    {...rest}
    classes={{ popper: className }}
  />
))(({ minWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "0px",
    width: "max-content",
    backgroundColor: "var(--navBg)",
    borderRadius: "6px",
    minWidth: minWidth,
    backdropFilter: "blur(var(--backdrop-filter))",
    border: "1px solid var(--border-dark)",
    boxShadow: "0px 0px 16px 0px var(--elevation-1)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--navBg)",
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: "1px solid var(--border-dark)",
  },
}));

const LightTooltipWithRef = React.forwardRef((props, ref) => {
  return <LightTooltip ref={ref} {...props} />;
});

export default PPLxHoverCard;
