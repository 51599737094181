import classes from "./DetailsFound.module.css";

const DetailsFound = ({ details, onUseDetails, onDiscard }) => {
  return (
    <div className={classes.details_found}>
      <div className={classes.header}>Details Found</div>
      <div className={classes.divider}></div>
      <div className={classes.details}>
        <div className={classes.img_and_title}>
          <img
            src={details.profile_image_url}
            alt="profile"
            className={classes.img}
          />
          <div className={classes.title}>{details.name}</div>
        </div>
        <div className={classes.description}>{details.description}</div>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.actionButtons}>
        <button className={classes.use_details} onClick={onUseDetails}>
          Use Details
        </button>
        <button className={classes.discard} onClick={onDiscard}>
          Discard
        </button>
      </div>
    </div>
  );
};

export default DetailsFound;
