import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  FlexGrow,
  TypeTag,
  Divider,
  RedirectLink,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./MethodHoverCard.module.css";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ConditionalLink from "shared/ConditionalLink";

const MethodHoverCard = ({ methodData, children }) => {
  return (
    <PPLxHoverCard content={<MethodHoverCardContent methodData={methodData} />}>
      {children}
    </PPLxHoverCard>
  );
};

const MethodHoverCardContent = ({ methodData }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.container}>
      <CustomColumn>
        <span className={classes.title}>{methodData?.verb}</span>
        <CustomRow>
          <FlexGrow>
            <CustomRow alignItems="center">
              <RedirectLink
                link={`https://www.4byte.directory/signatures/?bytes4_signature=0x${methodData?.methodId}`}>
                <span className={classes.subtitle}>{methodData?.methodId}</span>
              </RedirectLink>
            </CustomRow>
          </FlexGrow>
          <CustomRow justifyContent="flex-end">
            <TypeTag className={classes.tag} text="Method" />
          </CustomRow>
        </CustomRow>
        {methodData?.profile?.protocol_details && (
          <CustomColumn>
            <PaddingComponent height="12px" />
            <Divider />
            <PaddingComponent height="8px" />
            <CustomRow alignItems="center">
              <span className={classes.subtitle}>Method of</span>
              <ConditionalLink
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={methodData?.profile?.address_link}>
                <CustomRow>
                  <Image
                    src={methodData?.profile?.protocol_details?.icon}
                    alt={methodData?.profile?.protocol_details?.name}
                    imageType={IMAGE_TYPES.SMALL_CONTRACT}
                    className={classes.tokenImage}
                  />
                  <span className={classes.subtitle}>
                    {methodData?.profile?.protocol_details?.name ??
                      methodData?.profile?.display_name}
                  </span>
                </CustomRow>
              </ConditionalLink>
            </CustomRow>
          </CustomColumn>
        )}
      </CustomColumn>
    </div>
  );
};

export default MethodHoverCard;
