import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { mixpanelProjectToken } from "utils/constants";

import OpenReplay from "@openreplay/tracker";

import mixpanel from "mixpanel-browser";
// configuring sentry
import * as Sentry from "@sentry/react";

import { BrowserTracing } from "@sentry/tracing";
import { getTrackerDeviceId, getTrackerSessionId } from "utils/event_tracking";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

Sentry.init({
  dsn: "https://951ac0dfb7e70b4d1fcbeb2ebbd12030@sentry.0xppl.com/4",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production

  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== "development",
});

//initialise mixpanel for tracking users
if (process.env.NODE_ENV !== "development") {
  mixpanel.init(mixpanelProjectToken);
}

// configuring openreplay
export let tracker = null;
if (process.env.NODE_ENV !== "development") {
  tracker = new OpenReplay({
    // __DISABLE_SECURE_MODE: true, // for testing
    ingestPoint: "https://openreplay.0xppl.com/ingest",
    projectKey: "nQxnQIskN6vdHHcWayUc",
    network: {
      failuresOnly: false,
      ignoreHeaders: false,
      capturePayload: true,
    },
    disableCanvas: true,
    onStart: ({ sessionToken, sessionID }) => {
      // This is to link from Sentry -> OpenReplay
      Sentry.setTag("openReplaySessionToken", sessionToken);
      Sentry.setTag(
        "openReplaySessionURL",
        tracker.getSessionURL({ withCurrentTime: true })
      );
    },
  });

  tracker.start({
    metadata: {
      "0xpplDeviceId": getTrackerDeviceId(),
      "0xpplClientSessionId": getTrackerSessionId(),
    },
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
