import React, { useContext } from "react";
import { GlobalContext } from "contextStates/Global";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarPopUp = () => {
  const { snackBarState, setSnackBarState } = useContext(GlobalContext);
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setSnackBarState({ ...snackBarState, open: false })}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <Snackbar
      className="snackbar"
      anchorOrigin={{
        vertical: snackBarState?.vertical ?? "bottom",
        horizontal: snackBarState.horizontal ?? "left",
      }}
      open={snackBarState?.open}
      autoHideDuration={snackBarState?.duration ?? 2000}
      onClose={() => setSnackBarState({ ...snackBarState, open: false })}
      message={snackBarState?.message}>
      <Alert
        className="snackbar-alert"
        severity={snackBarState?.type}
        action={action}>
        {snackBarState?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarPopUp;
