import React, { useState } from "react";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useCopyToClipboard from "customHooks/useCopyToClipboard";
import { stopEventPropagation } from "utils/misc";
import { OffsetDiv } from "components/UI/Components/AddressComponent";

const Copyable = ({
  children,
  content,
  title = "Click to copy",
  showOnHover = false,
  copyIcon = null,
  disableHoverEffects = false,
  color = "var(--text-2)",
  defaultOpacity = "0.3",
  iconSize = "16px",
}) => {
  const [copyStatus, copyText] = useCopyToClipboard(content);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const disabledHoverClassName = disableHoverEffects ? "copyable__opaque" : "";

  return (
    <div
      className="copyable"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}>
      {children}
      {!showOnHover || isHovering || disableHoverEffects ? (
        <div onClick={(e) => stopEventPropagation(e)}>
          {!copyStatus ? (
            <button
              className={`copyable__button ${disabledHoverClassName}`}
              onClick={copyText}>
              <PPLxTooltip placement="top" title={title} arrow>
                {copyIcon || (
                  <ContentCopyIcon
                    className="copyable__button--icon"
                    sx={{ color, opacity: defaultOpacity }}
                  />
                )}
              </PPLxTooltip>
            </button>
          ) : (
            <button className={`copyable__copied ${disabledHoverClassName}`}>
              <PPLxTooltip placement="top" title="Copied" arrow>
                <CheckCircleIcon className="copyable__copied--icon" />
              </PPLxTooltip>
            </button>
          )}
        </div>
      ) : (
        <OffsetDiv />
      )}
    </div>
  );
};

export default Copyable;
