import Web3 from "web3";
import * as Sentry from "@sentry/react";
import Onboard from "@web3-onboard/core";
import walletConnectModule from "@web3-onboard/walletconnect";
import injectedModule from "@web3-onboard/injected-wallets";
import { logo, logoFull } from "utils/assets";
import coinbaseWallet from "./coinbase";
import base58 from "bs58";
import nacl from "tweetnacl";
import { PublicKey } from "@solana/web3.js";
import { getTrimedAddress } from "utils/misc";
import { tracker } from "index";
import { mixpanelSetAddress } from "utils/event_tracking";

export const WALLET_CHAIN_MISCONF = "WALLET_CHAIN_MISCONF";
export const TXN_TIMEOUT_ERROR = "TransactionBlockTimeoutError";

const appName = "0xppl";
const injected = injectedModule();
const coinbaseWalletSdk = coinbaseWallet();
export const WALLET_AUTORESPONSE_CUTOFF_MS = 100; // Consider wallet auto-responding if the response came faster than this
const wcv2InitOptions = {
  version: 2,
  projectId: "71eb1025659f4763befb6053c2bdb5ab",
  requiredChains: [],
  additionalOptionalMethods: [
    "wallet_switchEthereumChain",
    "wallet_addEthereumChain",
  ],
  qrModalOptions: {
    themeVariables: {
      "--wcm-z-index": 1503,
    },
  },
};
const walletConnect = walletConnectModule(wcv2InitOptions);

const modules = [injected, coinbaseWalletSdk, walletConnect];
export const chains = [
  {
    id: 1,
    token: "ETH",
    label: "Ethereum Mainnet",
    rpcUrl: "https://rpc.ankr.com/eth",
  },
  {
    id: "0x38",
    token: "BNB",
    label: "Binance Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
  {
    id: "0x89",
    token: "MATIC",
    label: "Polygon Mainnet",
    rpcUrl: "https://matic-mainnet.chainstacklabs.com",
  },
  {
    id: "0xa4b1",
    token: "ETH",
    label: "Arbitrum One",
    rpcUrl: "https://arbitrum.llamarpc.com",
  },
  {
    id: "0x4e454152",
    token: "ETH",
    label: "Aurora Mainnet",
    rpcUrl: "https://aurora.drpc.org",
  },
  {
    id: "0xa86a",
    token: "AVAX",
    label: "Avalanche C-Chain",
    rpcUrl: "https://avalanche.drpc.org",
  },
  {
    id: "0x2105",
    token: "ETH",
    label: "Base",
    rpcUrl: "https://base.llamarpc.com",
  },
  {
    id: "0x120",
    token: "ETH",
    label: "Boba Network",
    rpcUrl: "https://gateway.tenderly.co/public/boba-ethereum",
  },
  {
    id: "0x19",
    token: "CRO",
    label: "Cronos Mainnet",
    rpcUrl: "https://1rpc.io/cro",
  },
  {
    id: "0x2329",
    token: "EVMOS",
    label: "Evmos",
    rpcUrl: "https://evmos-evm.publicnode.com",
  },
  {
    id: "0xfa",
    token: "FTM",
    label: "Fantom Opera",
    rpcUrl: "https://fantom.publicnode.com",
  },
  {
    id: "0x64",
    token: "XDAI",
    label: "Gnosis",
    rpcUrl: "https://gnosis.publicnode.com",
  },
  {
    id: "0x80",
    token: "HT",
    label: "Huobi ECO Chain Mainnet",
    rpcUrl: "https://http-mainnet-node.huobichain.com",
  },
  {
    id: "0xe708",
    token: "ETH",
    label: "Linea",
    rpcUrl: "https://1rpc.io/linea",
  },
  {
    id: "0xa9",
    token: "ETH",
    label: "Manta Pacific Mainnet",
    rpcUrl: "https://1rpc.io/manta",
  },
  {
    id: "0x440",
    token: "METIS",
    label: "Metis Andromeda Mainnet",
    rpcUrl: "https://metis-mainnet.public.blastapi.io",
  },
  {
    id: "0x504",
    token: "GLMR",
    label: "Moonbeam",
    rpcUrl: "https://1rpc.io/glmr",
  },
  {
    id: "0x505",
    token: "MOVR",
    label: "Moonriver",
    rpcUrl: "https://moonriver.publicnode.com",
  },
  {
    id: "0xa",
    token: "ETH",
    label: "OP Mainnet",
    rpcUrl: "https://optimism.publicnode.com",
  },
  {
    id: "0x44d",
    token: "ETH",
    label: "Polygon zkEVM",
    rpcUrl: "https://polygon-zkevm.drpc.org",
  },
  {
    id: "0x76adf1",
    token: "ETH",
    label: "Zora",
    rpcUrl: "https://rpc.zora.energy",
  },
  {
    id: "0x144",
    token: "ETH",
    label: "zkSync",
    rpcUrl: "https://mainnet.era.zksync.io",
  },
];

const networkToChainId = {
  "matic-main": "Polygon",
  main: "Ethereum",
};

export let onboard;

export const initOnboard = () => {
  if (onboard) return onboard;

  onboard = Onboard({
    wallets: modules,
    apiKey: process.env["REACT_APP_BLOCKNATIVE_API_KEY"],
    chains,
    appMetadata: {
      name: "0xPPL",
      icon: logo,
      logo: logoFull,
      description: "Connect with 0xPPL",
      explore: "https://0xppl.com",
      recommendedInjectedWallets: [
        { name: "MetaMask", url: "https://metamask.io" },
      ],
    },
    accountCenter: {
      desktop: {
        enabled: false,
      },
      mobile: {
        enabled: false,
      },
    },
    notify: {
      desktop: {
        enabled: true,
        transactionHandler: (transaction) => {
          if (
            transaction.eventCode === "txPool" &&
            transaction.direction === "outgoing"
          ) {
            if (transaction.contractCall?.contractType === "erc20") {
              const link = `/${networkToChainId[transaction.network]}/tx/${
                transaction.hash
              }/`;
              const message =
                "Sending " +
                transaction.contractCall?.decimalValue +
                " " +
                (transaction.asset ?? transaction.contractCall?.contractName) +
                " to " +
                getTrimedAddress(transaction.contractCall?.params?._to);
              return {
                eventCode: "txPool",
                type: "pending",
                autoDismiss: 0,
                message,
                link,
                onClick: () => {
                  window.open(link, "_blank");
                },
              };
            }
          }
          if (
            transaction.eventCode === "txConfirmed" &&
            transaction.direction === "outgoing"
          ) {
            if (transaction.contractCall?.contractType === "erc20") {
              const link = `/${networkToChainId[transaction.network]}/tx/${
                transaction.hash
              }/`;
              const message =
                "Successfully sent " +
                transaction.contractCall?.decimalValue +
                " " +
                (transaction.asset ?? transaction.contractCall?.contractName) +
                " to " +
                getTrimedAddress(transaction.contractCall?.params?._to);
              return {
                eventCode: "txConfirmed",
                type: "success",
                autoDismiss: 10000,
                message,
                link,
                onClick: () => {
                  window.open(link, "_blank");
                },
              };
            }
          }
        },
      },
      mobile: {
        enabled: false,
      },
    },
    connect: {
      autoConnectLastWallet: true,
      autoConnectAllPreviousWallet: true,
    },
  });

  return onboard;
};

export const STORAGE_KEYS = Object.fromEntries(
  [
    "tokenSerialized",
    "tokenSignature",
    "userAddress",
    "sessionAddress",
    "sessionPrivateKey",
    "walletType",
  ].map((k) => [k, k])
);

export const clearAllQueryCache = (queryClient) => {
  queryClient.getQueryCache().clear();
};

export const clearAuthState = () => {
  Object.keys(STORAGE_KEYS).forEach((k) => {
    localStorage.removeItem(k);
  });
};

export const tsNow = () => parseInt(new Date().getTime() / 1000);

export const isAllAuthValidKeyAvailable = () => {
  return Object.keys(STORAGE_KEYS).every((k) => {
    return Boolean(localStorage.getItem(k));
  });
};

export const connectWalletGetProviderAddress = async ({
  skipReconnect = false,
}) => {
  if (skipReconnect && connectWalletGetProviderAddress.response) {
    return connectWalletGetProviderAddress.response;
  }

  let wallets;
  try {
    const onboard = initOnboard();
    await new Promise((r) => setTimeout(r, 100));
    wallets = await onboard.connectWallet();
  } catch (e) {
    console.error(e);
    throw new Error("Error connecting wallet. Please try again.");
  }
  // Check wallets array here.
  if (!wallets || wallets.length === 0) {
    throw new Error("No wallets returned. Please try again.");
  }

  const { accounts, provider } = wallets[0] ?? {};
  const fromAddr = accounts?.[0]?.address;
  const web3 = new Web3(provider);

  const resp = { provider, fromAddr, web3, wallets };
  if (provider == null || fromAddr == null) {
    throw new Error("Please connect your wallet to continue.");
  }
  connectWalletGetProviderAddress.response = resp;
  return resp;
};

export const connectWallet = async ({
  onConnect,
  onSignSuccess,
  isLocalStorageChange = true,
} = {}) => {
  const { web3, fromAddr } = await connectWalletGetProviderAddress({
    skipReconnect: false,
  });
  onConnect?.(web3);

  const { address: sessionAddress, privateKey: sessionPrivateKey } =
    web3.eth.accounts.create();
  const sessionTokenData = {
    app: appName,
    address: fromAddr,
    sessionAddress: sessionAddress,
    ts: tsNow(),
  };
  const tokenSerialized = JSON.stringify(sessionTokenData);
  let signature;
  try {
    signature = await web3.eth.personal.sign(
      tokenSerialized,
      fromAddr,
      "hello"
    );
  } catch (e) {
    console.error(e);
    throw new Error("Error signing message. Please try again.");
  }

  onSignSuccess?.(signature);
  // Result can be verified as below
  // Recovered signer will not match fromAddr if the signature has been tampered with.
  const signer = web3.eth.accounts.recover(tokenSerialized, signature);
  if (
    web3.utils.toChecksumAddress(signer) !==
    web3.utils.toChecksumAddress(fromAddr)
  ) {
    throw new Error(
      "Error signing session. Signer doesn't match connected wallet"
    );
  }

  //return session details here
  const sessionDetails = {
    tokenSerialized: tokenSerialized,
    tokenSignature: signature,
    userAddress: fromAddr,
    sessionAddress: sessionAddress,
    sessionPrivateKey: sessionPrivateKey,
    walletType: "EVM",
  };

  if (!isLocalStorageChange) {
    return sessionDetails;
  }
  clearAuthState();
  await saveSessionDetails(sessionDetails);

  return sessionDetails;
};

export const signInSolanaUtil = async ({
  onConnect,
  onSignSuccess,
  connect,
  connected,
  publicKey,
  signMessage,
  isLocalStorageChange = true,
}) => {
  if (!publicKey && !connected) {
    await connect();
  }
  onConnect?.();
  const web3 = new Web3();
  const { address: sessionAddress, privateKey: sessionPrivateKey } =
    web3.eth.accounts.create();
  const sessionTokenData = {
    app: appName,
    address: publicKey?.toBase58(),
    sessionAddress: sessionAddress,
    ts: tsNow(),
  };
  const tokenSerialized = JSON.stringify(sessionTokenData);
  const message = new TextEncoder().encode(tokenSerialized);
  const uint8arraySignature = await signMessage(message);
  const signature = base58.encode(uint8arraySignature);
  onSignSuccess?.(signature);

  const walletIsSigner = nacl.sign.detached.verify(
    message,
    uint8arraySignature,
    publicKey.toBuffer()
  );
  if (!walletIsSigner) {
    throw new Error(
      "Error signing session. Signer doesn't match connected wallet"
    );
  }

  //return session details here
  const sessionDetails = {
    tokenSerialized: tokenSerialized,
    tokenSignature: signature,
    userAddress: publicKey?.toBase58(),
    sessionAddress: sessionAddress,
    sessionPrivateKey: sessionPrivateKey,
    walletType: "SOLANA",
  };
  if (!isLocalStorageChange) {
    return sessionDetails;
  }
  clearAuthState();
  await saveSessionDetails(sessionDetails);

  return sessionDetails;
};

export const saveSessionDetails = async (sessionDetails) => {
  Object.keys(sessionDetails).forEach((k) => {
    if (!Object.keys(STORAGE_KEYS).includes(k)) {
      Sentry.captureMessage(
        `Invalid session update with key ${k}. Update: ${sessionDetails}`
      );
      throw new Error(
        "Oops, we broke something! Taking a look, please try again."
      );
    }

    Object.entries(sessionDetails).forEach(([k, v]) => {
      localStorage.setItem(k, v);
    });
  });
};

export const isSolana = () => {
  const walletType = localStorage.getItem("walletType");
  return walletType === "SOLANA";
};

export const handleNetworkSwitching = async ({ provider, ethChainId }) => {
  try {
    const hexChainId = ethChainId.toString(16);
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${hexChainId}` }], // hex format
    });
    return true;
  } catch (error) {
    switch (error?.code) {
      case 5000:
      case 5280:
      case 4902:
        // Wallet does not have this chain configured
        throw Error(WALLET_CHAIN_MISCONF);
      case -32002:
      case 4001:
        // User rejected request
        throw Error("User rejected chain switch request");
      case 4903:
        // Wallet does not support switching networks
        throw Error("Wallet does not support switching networks");
      default:
        // Other error
        throw Error("Error switching network");
    }
  }
};

export const signAndSendTransaction = async (tx) => {
  const { web3, fromAddr, provider } = await connectWalletGetProviderAddress({
    skipReconnect: true,
  });

  const currentNetworkChainId = await web3.eth.net.getId();
  if (tx.ethChainId !== currentNetworkChainId) {
    await handleNetworkSwitching({
      provider,
      ethChainId: tx.ethChainId,
    });
  }

  const sentTx = await web3.eth.sendTransaction({
    ...tx,
    from: fromAddr,
  });

  return sentTx;
};

export const addWalletChain = async (addWalletPayload) => {
  const { provider } = await connectWalletGetProviderAddress({
    skipReconnect: true,
  });

  const startTime = Date.now();
  await provider.request({
    method: "wallet_addEthereumChain",
    params: [addWalletPayload],
  });
  const runTimeMS = Date.now() - startTime;
  if (runTimeMS < WALLET_AUTORESPONSE_CUTOFF_MS) {
    // Chain already exists in Metamask
    return "Looks like the chain already exists in Metamask";
  } else {
    return "Chain added to Metamask";
  }
};

export const navigateUserPath = ({ identityData }) => {
  if (!identityData) return;
  if (
    !identityData.onboarding_state ||
    identityData.onboarding_state.current !== "complete"
  ) {
    if (identityData.onboarding_state === undefined) {
      return "/onboarding";
    } else {
      return `/onboarding/${identityData.onboarding_state.current}`;
    }
  } else {
    return "/home";
  }
};

export const routeFromWaitlistResp = ({ data }) => {
  const currRoute = `/onboarding/${data?.data?.current_step}`;
  if (data?.data?.current_step) {
    return currRoute;
  }
};

export const isSolanaWallet = async ({ address }) => {
  try {
    const publicKey = new PublicKey(address);
    return await PublicKey.isOnCurve(publicKey);
  } catch (e) {
    return false;
  }
};

export const isEVMAddress = (address) => {
  return Web3.utils.isAddress(address);
};

export const isAddressEqual = (a, b) => {
  if (isEVMAddress(a) && isEVMAddress(b)) {
    return Web3.utils.toChecksumAddress(a) === Web3.utils.toChecksumAddress(b);
  } else {
    return a === b;
  }
};

export const signDynamicWallet = async ({ wallet, message, logout }) => {
  if (!wallet || !message) {
    throw new Error("Primary wallet not found");
  }
  const web3 = new Web3(window?.ethereum ?? null);
  const fromAddr = wallet.address;
  const isSolana = wallet.chain === "SOL";
  const connector = isSolana ? wallet.connector : wallet.connector.ethers;
  const signer = await connector.getSigner();
  const tokenSerialized = isSolana
    ? new TextEncoder().encode(message)
    : message;
  if (!signer) {
    throw new Error("Error in signing message");
  }
  const signature = await signer.signMessage(tokenSerialized);
  if (!isSolana) {
    const newSign = web3.eth.accounts.recover(tokenSerialized, signature);
    if (
      web3.utils.toChecksumAddress(newSign) !==
      web3.utils.toChecksumAddress(fromAddr)
    ) {
      throw new Error(
        "Error signing session. Signer doesn't match connected wallet"
      );
    }
  }
  // @TODO - verify signature for solana

  // remove wallet from being connected
  if (logout) {
    logout();
  }
  return signature;
  //return session details here
};

export const signWithFarcaster = async ({ signature, message, fromAddr }) => {
  const web3 = new Web3(window?.ethereum ?? null);
  const { address: sessionAddress, privateKey: sessionPrivateKey } =
    web3.eth.accounts.create();
  const tokenSerialized = {
    app: appName,
    address: fromAddr,
    sessionAddress: sessionAddress,
    ts: tsNow(),
    message: message,
  };
  if (signature) {
    const signer = web3.eth.accounts.recover(message, signature);
    if (
      web3.utils.toChecksumAddress(signer) !==
      web3.utils.toChecksumAddress(fromAddr)
    ) {
      throw new Error(
        "Error verifying signature. Signer doesn't match connected wallet"
      );
    }

    const sessionDetails = {
      tokenSerialized: JSON.stringify(tokenSerialized),
      tokenSignature: signature,
      userAddress: fromAddr,
      sessionAddress,
      sessionPrivateKey,
      walletType: "EVM",
    };
    mixpanelSetAddress({ address: fromAddr });
    tracker?.setUserAnonymousID(`${fromAddr}`);
    saveSessionDetails(sessionDetails);
    localStorage.setItem("pusherChannelSessionAddressId", fromAddr);
    return { sessionAddress, sessionPrivateKey, tokenSerialized };
  }
  throw new Error("Error signing session. Please try again");
};
