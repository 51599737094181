import { useEffect, useRef } from "react";

export default function useIdleTimer({ delay, onIdle }) {
  const timer = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(onIdle, delay);
    };

    const onAction = () => {
      resetTimer();
    };

    resetTimer();

    window.addEventListener("mousemove", onAction);
    window.addEventListener("keypress", onAction);

    return () => {
      clearTimeout(timer.current);
      window.removeEventListener("mousemove", onAction);
      window.removeEventListener("keypress", onAction);
    };
  }, [delay, onIdle]);
}
