import React, { useContext } from "react";
import { PencilSimple } from "@phosphor-icons/react";
import { AuthContext } from "contextStates/AuthContext";
import IconButton from "@mui/material/IconButton";
import { GlobalContext } from "contextStates/Global";

const EditContractDetailsButton = ({ contractData, refetch }) => {
  const { isSuperuser } = useContext(AuthContext);
  const { editContractPopupDetails, setIsEditContractPopupShown } =
    useContext(GlobalContext);

  return (
    <>
      {isSuperuser && (
        <IconButton size="sm">
          <PencilSimple
            onClick={(event) => {
              editContractPopupDetails.current = contractData;
              setIsEditContractPopupShown(true);
            }}
            fontSize="18px"
            color="var(--text-2)"
          />
        </IconButton>
      )}
    </>
  );
};

export default EditContractDetailsButton;
