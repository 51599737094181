import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)(({ errorState }) => ({
  // "& .Mui-focused.MuiFormLabel-root, & .MuiFormControl-root-MuiTextField-root label ":
  //   {},
  "& .MuiInputLabel-outlined": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    fontSize: "13px",
    paddingLeft: "10px",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(5px, -9px) scale(0.9)",
  },

  "& label": {
    color: "#687684",
    paddingLeft: "10px",
    "& Mui-focused": {
      color: "#687684",
    },
  },
  "& input": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    color: "var(--text-1)",
  },
  "& textarea": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    color: "var(--text-1)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(104, 118, 132, 0.1)",
      borderSize: "1px",
      paddingLeft: "14px",
    },
    "& fieldset,  &.MuiOutlinedInput-root.Mui-focused fieldset, .MuiOutlinedInput-root.Mui-focused:hover fieldset ":
      {
        ...(errorState ? { borderColor: "var(--error)", outline: "none" } : {}),
      },
    "&:hover fieldset": {
      borderColor: "rgba(104, 118, 132, 0.3)",
      borderSize: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(6, 72, 215, 0.24)",
      borderSize: "1px",
    },
    "& MuiInputBase-input": {
      fontWeight: "500",
    },
    "& .MuiIconButton-root": {
      color: "var(--text-2)",
    },
    "& .Mui-disabled": {
      color: "var(--text-2)",
      "-webkitTextFillColor": "var(--text-2)",
    },
    "& .Mui-disabled.MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(104, 118, 132, 0.1)",
    },
  },
  backgroundColor: "var(--base)",
  borderRadius: "4px",
}));

export default CustomTextField;
