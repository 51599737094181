import { MenuItem } from "@mui/material";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import Image from "components/UI/Image";
import styled from "styled-components/macro";
import ConditionalLink from "shared/ConditionalLink";

// TokenTransfers
export const TokenTransfersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const TokenSeparator = styled.div`
  color: rgb(104, 118, 132, 0.6);
  margin: 0 8px 0 2px;
  align-self: baseline;
  font-size: 16px;
`;
export const TokenTransferItem = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
  gap: 5px;
`;
export const Amount = styled.div`
  display: flex;
  align-items: center;
  color: var(--text-1);
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
  gap: 2px;
`;

export const TokenInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const TokenName = styled.div`
  color: #687684;
  font-weight: 400;
`;
export const TokenImageWrapper = styled.div`
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;
export const TokenImage = styled(Image)`
  height: 16px;
  width: auto;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// RenderActivity
export const ActivityItemWrapper = styled.div`
  ${(props) =>
    props.isActivityDetails
      ? `
      background: ${props.isBackgroundColor ?? true ? "var(--base)" : "none"};
      padding:${props.padding ?? "14px 20px"};
      border: ${
        props.isBorder ?? true ? "1px solid var(--border-dark)" : "none"
      };
      border-radius: 6px;
      margin-bottom: ${props.marginBottom ?? "16px"};
    `
      : ``}
  display: flex;
`;

export const ActivityItem = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;
export const ActivityContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: 4px;
`;
export const ActivityIcon = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--elevation-2);
  border-radius: 50%;
`;
export const ContentWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;
export const ActivitiesDivider = styled.div`
  border-bottom: 1px solid var(--border-dark);
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

export const ActivityExtraInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const TimeLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #687684;
  letter-spacing: normal;
`;
export const ChainIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
`;
export const SettingsWrapper = styled.button`
  padding: 0;
  background: none;
`;
export const SettingsIcon = styled.img`
  display: flex;
  width: 18px;
  height: 4px;
`;

export const TokenTransfersUsdValue = styled.div`
  color: var(--text-3);
  letter-spacing: -2%;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  flex-grow: 1;
  text-align: right;
`;
export const StyledMenuItem = styled(MenuItem)`
  &:not(:last-of-type) {
    &::before {
      content: "";
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 0;
      border-bottom: 1px dashed rgb(104, 118, 132, 0.16);
    }
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: normal;
  color: #232427;
`;
export const MenuIcon = styled.img`
  height: 10px;
  width: 10px;
`;

// NftTransfers
export const NftTransfersWrapper = styled(ScrollContainer)`
  display: flex;
  gap: 8px;
  overflow: auto;
  margin-top: 16px;
  margin-bottom: 8px;
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
`;
export const SharedStyles = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
`;
export const MediaPreviewHorizontalSeparator = styled.div`
  height: 2px;
  width: 100%;
  background: var(--border-dark);
`;
export const MediaPreviewVerticalSeparator = styled.div`
  width: 1px;
  height: 100%;
  background: var(--border-dark);
`;
export const GroupActivityPreviewWrapper = styled(ConditionalLink)`
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
`;
export const NftItem = styled(ConditionalLink)`
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
`;
export const LastNftItem = styled(SharedStyles)`
  top: 0;
  background: rgba(35, 36, 39, 0.1);
  backdrop-filter: blur(2px);
`;

export const LastMediaPreviewItem = styled(SharedStyles)`
  top: 0;
  background: var(--navBg);
  opacity: 0.8;
  backdrop-filter: blur(4px);
`;
export const MediaPreviewCaption = styled(SharedStyles)`
  align-items: center;
  color: var(--text-1);
  display: flex;
  height: 100%;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: normal;
`;

export const NftCaption = styled(SharedStyles)`
  height: 14px;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(35, 36, 39, 0.4);
  backdrop-filter: blur(4px);
  padding: 0 6px;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-weight: 600;
  font-size: 11px;
  line-height: 130%;
`;

// GroupFeedItem
export const GroupedActivitiesWrapper = styled.div`
  display: flex;
  background: var(--base);
  padding: 20px 24px;
  gap: 20px;
  font-size: 14px;
  border-bottom: 1px solid #6775831a;
`;
export const AvatarWrapper = styled.div``;
export const AvatarLink = styled(Link)`
  display: block;
`;
export const ActorAvatar = styled(Image)`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
`;
export const GroupContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ActorDetailsHeader = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  letter-spacing: normal;
  line-height: 130%;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 130%;
  width: fit-content;
  margin-bottom: 6px;
`;
export const ActorName = styled.div`
  font-weight: 600;
  color: var(--text-1);
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Address = styled.div`
  font-weight: 400;
  color: #687684;
`;
export const MethodName = styled.span`
  color: #2d8690;
  font-weight: 500;
  cursor: pointer;
`;
export const PersonName = styled(ConditionalLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-1);
  font-weight: 500;
`;
export const DisplayName = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const FeedDescriptionWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  word-break: break-word;
  line-height: 150%;
  font-size: 15px;
  font-weight: 400;

  a:hover {
    text-decoration: underline;
    text-decoration-color: var(--text-2);
  }
  ${(props) => `
    font-size: ${props.fontSize};
    color: ${props.fontColor};
  `}
  font-family: "Roboto";
`;
export const AdminDescriptionWrapper = styled.div`
  & ${FeedDescriptionWrapper} {
    color: #00000080;
  }
`;
export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ToggleMoreActivities = styled.button`
  display: inline-flex;
  background: none;
  color: #687684;
  padding: 0 !important;
  font-family: Roboto;
  font-size: 14px;
`;
export const GroupedActivityFooter = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 445px;
  margin-top: 20px;
`;

export const FooterActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
`;
export const ActionButtonIcon = styled.img`
  height: 10px;
  width: 10px;
`;
export const ActionButtonLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #687684;
`;
