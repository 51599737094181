import defaultProfileImage from "assets/webp/profile_picture.webp";
import { getLocallyHostedImage } from "utils/misc";
import nftFallback from "assets/svg/feed/nft_fallback.svg";
import smallNftFallback from "assets/svg/feed/small_nft_fallback.svg";
import avatarFallback from "assets/svg/feed/avatar_fallback.svg";
import smallAvatarFallback from "assets/svg/feed/small_avatar_fallback.svg";
import tokenFallback from "assets/svg/feed/token_fallback.svg";
import smallTokenFallback from "assets/svg/feed/small_token_fallback.svg";
import contractFallback from "assets/svg/feed/contract_fallback.svg";
import smallContractFallback from "assets/svg/feed/small_contract_fallback.svg";
import React, { forwardRef, useContext, useMemo, useState } from "react";
import { GlobalContext } from "contextStates/Global";

const DEFAULT_IMAGES = [
  "blue.svg",
  "blue-1.svg",
  "blue-2.svg",
  "blue-3.svg",
  "blue-4.svg",
  "blue-5.svg",
  "blue-6.svg",
  "blue-7.svg",
  "dpink-29.svg",
  "dpink-30.svg",
  "dpink-31.svg",
  "dpink-32.svg",
  "dpink-33.svg",
  "dpink-34.svg",
  "dpink-35.svg",
  "grey-19.svg",
  "grey-20.svg",
  "grey-21.svg",
  "grey-22.svg",
  "grey-23.svg",
  "orange-8.svg",
  "orange-9.svg",
  "orange-10.svg",
  "orange-11.svg",
  "orange-12.svg",
  "orange-13.svg",
  "orange-14.svg",
  "pink-24.svg",
  "pink-25.svg",
  "pink-26.svg",
  "pink-27.svg",
  "pink-28.svg",
  "purple-36.svg",
  "purple-37.svg",
  "purple-38.svg",
  "purple-39.svg",
  "violet-15.svg",
  "violet-16.svg",
  "violet-17.svg",
  "violet-18.svg",
];
export const IMAGE_TYPES = {
  AVATAR: "avatar",
  SMALL_AVATAR: "small_avatar",
  CONTRACT: "contract",
  SMALL_CONTRACT: "small_contract",
  TOKEN: "token",
  SMALL_TOKEN: "small_token",
  NEW_FALLBACK: "new_fallback",
  NFT: "nft",
  SMALL_NFT: "small_nft",
  CHAIN: "chain",
  NO_FALLBACK: "no_fallback",
};

export const getDefaultAvatarSrc = (input) => {
  const baseFolder = "/default_avatars/";
  // If input is empty, return the first profile image
  if (!input) {
    return `${baseFolder}${DEFAULT_IMAGES[0]}`;
  }

  // For non-empty input, generate a consistent index based on input string
  const hash = [...input].reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const derivedImage = hash % DEFAULT_IMAGES.length;
  if (!derivedImage) {
    return `${baseFolder}${DEFAULT_IMAGES[0]}`;
  }
  return `${baseFolder}${DEFAULT_IMAGES[derivedImage]}`;
};

export const getDefaultImage = ({ imageType, name = null }) => {
  switch (imageType) {
    case IMAGE_TYPES.AVATAR:
      return getDefaultAvatarSrc(name);
    case IMAGE_TYPES.SMALL_AVATAR:
      return smallAvatarFallback;
    case IMAGE_TYPES.NEW_FALLBACK:
      return avatarFallback;
    case IMAGE_TYPES.NFT:
      return nftFallback;
    case IMAGE_TYPES.SMALL_NFT:
      return smallNftFallback;
    case IMAGE_TYPES.CONTRACT:
      return contractFallback;
    case IMAGE_TYPES.SMALL_CONTRACT:
      return smallContractFallback;
    case IMAGE_TYPES.TOKEN:
      return tokenFallback;
    case IMAGE_TYPES.SMALL_TOKEN:
      return smallTokenFallback;
    case IMAGE_TYPES.CHAIN:
      return contractFallback;
    case IMAGE_TYPES.NO_FALLBACK:
      return null;
    default:
      return defaultProfileImage;
  }
};

const Image = forwardRef(
  (
    {
      src,
      alt,
      imageType = IMAGE_TYPES.AVATAR,
      className,
      fallbackClassName,
      style,
      name = null,
      onLoad = () => {},
      onErrorCallback = () => {},
      ...props
    },
    ref
  ) => {
    const { brokenImagesDict, updateBrokenImagesDict } =
      useContext(GlobalContext);
    const [disableImage, setDisableImage] = useState(false);
    if (src === "assets/png/unknown_token_logo.png") {
      src = "";
    }
    const source = useMemo(() => {
      if (brokenImagesDict.current[src]) {
        return getDefaultImage({ imageType, name });
      }
      try {
        const transformedSrc = getLocallyHostedImage(src);
        if (transformedSrc == null) {
          if (imageType === IMAGE_TYPES.NO_FALLBACK) return null;
          onErrorCallback();
          return getDefaultImage({ imageType, name });
        }
        return transformedSrc;
      } catch (err) {
        if (imageType === IMAGE_TYPES.NO_FALLBACK) return null;
        onErrorCallback();
      }
    }, [src, imageType, onErrorCallback, brokenImagesDict, name]);

    const onError = (e) => {
      e.stopPropagation();
      updateBrokenImagesDict(src);
      if (imageType === IMAGE_TYPES.NO_FALLBACK) {
        setDisableImage(true);
      } else {
        e.target.src = getDefaultImage({ imageType, name }) ?? tokenFallback;
      }
      onErrorCallback();
      if (fallbackClassName) {
        e.target.classList.add(fallbackClassName);
      }
    };

    return (
      !disableImage &&
      source && (
        <img
          ref={ref}
          src={source}
          alt={alt}
          {...props}
          style={style}
          className={className}
          onError={onError}
          onLoad={onLoad}
        />
      )
    );
    //   uncomment the below line for testing
    // return <img src={`${source}break`} alt={alt} {...props} onError={onError} />;
  }
);

export default React.memo(Image);
